import { PricingEstimatesSteps } from "../../../../pricing-estimates-steps/pricing-estimates-steps.component";
import "../../fl-pricing-drawer-content.scss";
import { AprPercentForm } from "../apr-percent-form/apr-percent-form.component";
import { FinanceType } from "@src/constants";
import { useGeneralContext } from "@src/context/general-context/general-context";
import { useAppContext } from "@src/context/app-context";
import { formatCurrency } from "@src/helpers";

export const FinanceLeasePricingDrawerContentInfo = ({ toggleContentApr }) => {
  const {
    contextState: { dealerPricing },
  } = useGeneralContext();
  const {
    state: {
      user: {
        customer: { first_name },
      },
      order: {
        finance_type,
        selling_price,
        order_asset: { unit_price, msrp },
      },
    },
  } = useAppContext();
  return (
    <>
      <div className="fl-pricing-drawer-wrap">
        <div className="fl-pricing-drawer-wrap--intro">
          <h3 className="text--h3">
            Get a more precise estimate for monthly payments.
          </h3>
        </div>
        <PricingEstimatesSteps
          stepCount={"01."}
          title={`Thanks for logging in, ${first_name}. Dealer pricing is now unlocked.`}
          iconName="checkmark"
          content={
            dealerPricing && (
              <div className="fl-pricing-drawer-wrap--inner">
                <div className="resulting-price-wrap">
                  <div className="resulting-price-wrap--inner">
                    <p className="text--promo-focus-price">
                      {formatCurrency(unit_price)}
                    </p>
                    <p className="text--body-2">
                      {formatCurrency(unit_price)} MSRP, with a{" "}
                      {formatCurrency(selling_price - msrp)} dealer credit
                      applied.
                    </p>
                  </div>
                </div>
              </div>
            )
          }
        />
        <PricingEstimatesSteps
          stepCount={"02."}
          title={
            finance_type === FinanceType.LEASE
              ? "Get more Precise monthly payments"
              : "Get a more accurate APR %."
          }
          iconName="lock_unlocked"
          description={
            finance_type === FinanceType.LEASE
              ? "Provide your address to verify your identity with TransUnion and get a more accurate monthly payment. This soft credit pull does not impact your credit score."
              : "Provide your address to verify your identity with TransUnion and get an accurate APR %. This soft credit pull does not impact your credit score."
          }
          content={
            <div className="fl-pricing-drawer-wrap--inner">
              <AprPercentForm toggleContentApr={toggleContentApr} />
            </div>
          }
        />
      </div>
    </>
  );
};
