import * as yup from "yup";
export const getEmployedApplicableFieldValidation = (fieldName) =>
  ["Employed", "Self Employed"].includes(fieldName);

export const financialProfileValidator = (showAdditionalIncome) => {
  return yup.object().shape({
    employment_type: yup.string().required("Employment type is required"),
    // annual_other_income: yup.number().when([], {
    //   is: () => showAdditionalIncome,
    //   then: () =>
    //     yup
    //       .number()
    //       .required("Annual Other Income is required")
    //       .typeError("Annual income must be a valid number")
    //       .max(9999999999, "Max Annual income is 10 digits")
    //       .positive("Annual income must be a positive number"),
    //   otherwise: () => yup.number().notRequired(),
    // }),

    // source_of_other_income: yup
    //   .string()
    //   .nullable()
    //   .when("annual_other_income", {
    //     is: (value: any) => value > 0,
    //     then: () =>
    //       yup
    //         .string()
    //         .required("Source of Other Income is required")
    //         .max(
    //           100,
    //           "Source of Other Income maximum length is up to 100 characters"
    //         ),
    //   }),
    occupation: yup.string().when("employment_type", {
      is: (employment_type: string) => employment_type === "Employed",
      then: () =>
        yup
          .string()
          .required("Occupation is required")
          .max(100, "Occupation must be less than 100 characters"),
      otherwise: () => yup.string().nullable(),
    }),
    annual_employment_income: yup.string().when("employment_type", {
      is: (employment_type: string) => employment_type === "Employed",
      then: () =>
        yup
          .number()
          .required("Annual income is required")
          .typeError("Annual income must be a valid number")
          .max(9999999999, "Max Annual income is 10 digits")
          .positive("Annual income must be a positive number"),
      otherwise: () => yup.string().nullable(),
    }),
    employed_since: yup.date().when("employment_type", {
      is: (employment_type: string) => employment_type === "Employed",
      then: () => yup.date().required("Employed since is required"),
      otherwise: (schema) => yup.string().nullable(),
    }),
    name: yup.string().when("employment_type", {
      is: (employment_type: string) => employment_type === "Employed",
      then: () => yup.string().required("Employer name is required"),
      otherwise: () => yup.string().nullable(),
    }),
    phone: yup.string().when("employment_type", {
      is: (employment_type: string) => employment_type === "Employed",
      then: () => yup.string().required("Employer phone is required"),
      otherwise: () => yup.string().nullable(),
    }),
    address: yup.object().when("employment_type", {
      is: (employment_type: string) =>
        getEmployedApplicableFieldValidation(employment_type),
      then: () =>
        yup.object({
          address_line_1: yup.string().required("Address line 1 is required"),
          address_line_2: yup.string().nullable(),
          city: yup.string().required("City is required"),
          state_name: yup.string().required("State is required"),
          zip_code: yup
            .string()
            .nullable()
            .notRequired()
            .test("zip-code-validation", "Invalid ZIP code", (value) => {
              if (!value) return true;

              return /^[0-9]{5}$/.test(value);
            }),
        }),
    }),
    financial_profile: yup.object().shape({
      residence: yup.string().required("Residence is required"),
      bankruptcy: yup.string().required("Bankruptcy status is required"),
      monthly_payment: yup.string().when("residence", {
        is: (residence: string) =>
          residence === "Mortgage" || residence === "Renting",
        then: () =>
          yup
            .string()
            .required("Monthly payment is required")
            .test(
              "is-positive",
              "Monthly payment must be greater than 0",
              (value) => {
                return parseFloat(value) > 0;
              }
            ),
        otherwise: () => yup.string().nullable(),
      }),
    }),
  });
};
