export enum ActionType {
  LOAD_CHAT = "LOAD_CHAT",
  LOAD_TENANT_CONFIGURATIONS = "LOAD_TENANT_CONFIGURATIONS",
  LOAD_CURRENCY_CONFIGURATIONS = "LOAD_CURRENCY_CONFIGURATIONS",
  CHANGE_USER_LOGGED_IN_STATE = "CHANGE_USER_LOGGED_IN_STATE",
  UPDATE_HEADER_FOOTER = "UPDATE_HEADER_FOOTER",
  UPDATE_STATES_OPTIONS = "UPDATE_STATES_OPTIONS",
  UPDATE_FNI_OPTIONS = "UPDATE_FNI_OPTIONS",
  CACHE_DATE = "CACHE_DATE",
  UPDATE_CONTRACT_DOCS = "UPDATE_CONTRACT_DOCS",
  UPDATE_CUSTOMER = "UPDATE_CUSTOMER",
  UPDATE_COLLAPSE_STATE = "UPDATE_COLLAPSE_STATE",
  UPDATE_DEALER_PROFILE = "UPDATE_DEALER_PROFILE",
  LOAD_DEFAULT_LENDER_CONFIGURATIONS = "LOAD_DEFAULT_LENDER_CONFIGURATIONS",
  UPDATE_TENANT_THEME = "UPDATE_TENANT_THEME",
  PLACEHOLDERS = "PLACEHOLDERS",
  ORDER_FRAUD_STATUS = "ORDER_FRAUD_STATUS",
  ORDER_INFO = "ORDER_INFO",
  ORDER_INSURANCE = "ORDER_INSURANCE",
  DEALER_CODE = "DEALER_CODE",
  UPDATE_ORDER_INFO = "UPDATE_ORDER_INFO",
  UPDATE_SCORE = "UPDATE_SCORE",
  UPDATE_INSURANCE = "UPDATE_INSURANCE",
  UPDATE_LENDERS = "UPDATE_LENDERS",
  UPDATE_LENDER_SUBMISSIONS = "UPDATE_LENDER_SUBMISSIONS",
  STORE_SETUP_DATA = "STORE_SETUP_DATA",
  SOCURE = "SOCURE",
  TRANSUNION = "TRANSUNION",
  UPDATE_DISCLAIMERS = "UPDATE_DISCLAIMERS",
  LOGO = "LOGO",
  ORDER_STATUS = "ORDER_STATUS",
  ORDER_DISCLAIMER = "ORDER_DISCLAIMER",
  UPDATE_REBATE = "UPDATE_REBATE",
  DEFAULT_STATE = "DEFAULT_STATE",
  ADDONS = "ADDONS",
  TOGGLE_DISCLAIMER = "TOGGLE_INCENTIVE_AND_DISCOUNT_DISCLAIMER",
  PREINSTALLCOUNT = "PREINSTALLCOUNT",
  TOGGLE_DRAWER_STATUS = "TOGGLE_DRAWER_STATUS",
  TOGGLE_MODAL = "TOGGLE_MODAL",
  DEAL = "DEAL",
  UPDATE_ORDER_NET_FINANCE_AMOUNT = "UPDATE_ORDER_NET_FINANCE_AMOUNT",
  PROGRAM_ERROR = "PROGRAM_ERROR",
  INFO_ERROR = "INFO_ERROR",
  CREDIT_FREEZE = "CREDIT_FREEZE",
}

// Action methods
export const loadTenantConfigurations = (configurations: object) => ({
  type: ActionType.LOAD_TENANT_CONFIGURATIONS,
  payload: configurations,
});

export const loadDefaultLenderConfigurations = (configurations: object) => ({
  type: ActionType.LOAD_DEFAULT_LENDER_CONFIGURATIONS,
  payload: configurations,
});

export const storeSetupData = (setupData: object) => ({
  type: ActionType.STORE_SETUP_DATA,
  payload: setupData,
});

export const loadDefaultCurrencyConfigurations = (curreny: object) => ({
  type: ActionType.LOAD_CURRENCY_CONFIGURATIONS,
  payload: curreny,
});

export const openChatBox = (ChatOpen: boolean) => ({
  type: ActionType.LOAD_CHAT,
  payload: ChatOpen,
});
export const changeUserLoggedInState = (isLoggedIn: boolean) => ({
  type: ActionType.CHANGE_USER_LOGGED_IN_STATE,
  payload: isLoggedIn,
});
export const updateHeaderFooter = (
  icons: {},
  headerLinks: [],
  footerLinks: [],
  dealorId
) => ({
  type: ActionType.UPDATE_HEADER_FOOTER,
  payload: {
    icons: icons,
    header_links: headerLinks,
    footer_links: footerLinks,
    dealor_id: dealorId,
  },
});

export const defaultStateUpdate = (state) => ({
  type: ActionType.DEFAULT_STATE,
  payload: state,
});

export const updateCustomer = (customerRes) => ({
  type: ActionType.UPDATE_CUSTOMER,
  payload: customerRes,
});

export const updateScore = (score) => ({
  type: ActionType.UPDATE_SCORE,
  payload: score,
});

export const updateStates = (states) => ({
  type: ActionType.UPDATE_STATES_OPTIONS,
  payload: states,
});

export const updateFniOptions = (payload) => ({
  type: ActionType.UPDATE_FNI_OPTIONS,
  payload,
});

export const updateDateCache = (payload) => ({
  type: ActionType.CACHE_DATE,
  payload,
});

export const updateContractDocuments = (documents) => ({
  type: ActionType.UPDATE_CONTRACT_DOCS,
  payload: documents,
});

export const updateProgramerror = (error) => ({
  type: ActionType.PROGRAM_ERROR,
  payload: error,
});

export const updateInfoerror = (error) => ({
  type: ActionType.INFO_ERROR,
  payload: error,
});

export const updateCreditFreeze = (error) => ({
  type: ActionType.CREDIT_FREEZE,
  payload: error,
});

export const setOrderInfo = (response) => ({
  type: ActionType.UPDATE_ORDER_INFO,
  payload: response,
});

export const updateCollapseState = () => ({
  type: ActionType.UPDATE_COLLAPSE_STATE,
});

export const updateDealerProfile = (dealerCode) => ({
  type: ActionType.UPDATE_DEALER_PROFILE,
  payload: dealerCode,
});

export const updateTenantTheme = (theme) => ({
  type: ActionType.UPDATE_TENANT_THEME,
  payload: theme,
});
export const updateSocure = (socure: boolean) => ({
  type: ActionType.SOCURE,
  payload: socure,
});

export const updateTransunion = (socure: boolean) => ({
  type: ActionType.TRANSUNION,
  payload: socure,
});

export const loadPlaceholder = (configurations: object) => ({
  type: ActionType.PLACEHOLDERS,
  payload: configurations,
});

export const orderFraudStatus = (status: string) => ({
  type: ActionType.ORDER_FRAUD_STATUS,
  payload: status,
});
export const orderInsurance = (insurance: any) => ({
  type: ActionType.ORDER_INSURANCE,
  payload: insurance,
});

export const updateInsuraneProviderOptions = (insurance_company: any) => ({
  type: ActionType.UPDATE_INSURANCE,
  payload: insurance_company,
});
export const updateLenders = (lenderdata: any) => ({
  type: ActionType.UPDATE_LENDERS,
  payload: lenderdata,
});
export const updateLenderSubmissions = (lenderdata: any) => ({
  type: ActionType.UPDATE_LENDER_SUBMISSIONS,
  payload: lenderdata,
});
export const updateDisclaimers = (disclaimers: any) => ({
  type: ActionType.UPDATE_DISCLAIMERS,
  payload: disclaimers,
});
export const updateLogo = (logo: string) => ({
  type: ActionType.LOGO,
  payload: logo,
});
export const updateRebate = (rebate: any) => ({
  type: ActionType.UPDATE_REBATE,
  payload: rebate,
});
export const updateaddOns = (addon: any) => ({
  type: ActionType.ADDONS,
  payload: addon,
});

export const toggleDisclaimer = (payload: any) => ({
  type: ActionType.TOGGLE_DISCLAIMER,
  payload: payload,
});
export const updatePreInstalled = (addon: any) => ({
  type: ActionType.PREINSTALLCOUNT,
  payload: addon,
});
export const toggleDrawerStatus = (payload: any) => ({
  type: ActionType.TOGGLE_DRAWER_STATUS,
  payload: payload,
});
export const toggleModalAction = (payload: any) => ({
  type: ActionType.TOGGLE_MODAL,
  payload: payload,
});

export const updateOrderNetFinanceAmount = (amount: any) => ({
  type: ActionType.UPDATE_ORDER_NET_FINANCE_AMOUNT,
  payload: amount,
});
