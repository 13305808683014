import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { http } from "../utilities";
import {
  CONFIG_BASE_URL,
  DEALER_PREFERENCE_BY_DEALER_CODE,
  DMS_BASE_URL,
  VEHICLE,
  VEHICLE_DETAIL,
} from "@src/constants/routes";
interface VehicleResponse {
  detail: any; // Replace 'any' with the actual type you expect for 'detail'
  // other properties from the API response
}
export const useGetVehicleDetail = (): {
  isPending: boolean;
  error: string | undefined;
  mutate: UseMutateFunction<
    any | { detail?: { message: string } },
    { detail: { message: string } },
    any
  >;
} => {
  const { mutate, isPending, error } = useMutation<
    any | { detail?: { message: string } },
    { detail: { message: string } },
    any
  >({
    mutationFn: async ({ vin, company_id, dealerCode }: any) => {
      let vehicleDetailResponse = await http(CONFIG_BASE_URL)
        .get(`${VEHICLE}/${vin}?dealer_code=${dealerCode}`, {
          headers: { company_id: company_id.toString() },
        })
        .then((res) => res.data);

      if (vehicleDetailResponse && vehicleDetailResponse.detail) {
        return {
          vehicleDetail: vehicleDetailResponse,
          dealerPref: {},
          preInstalledAddOns: [],
        };
      }
      let vehicleByVinResponse = await http(CONFIG_BASE_URL)
        .get(`${VEHICLE_DETAIL}/${vehicleDetailResponse.dealer_code}/${vin}`, {
          headers: { company_id: company_id.toString() },
        })
        .then((res) => res.data);

      if (vehicleByVinResponse.detail) {
        return {
          vehicleDetail: {
            ...vehicleDetailResponse,
            ...vehicleByVinResponse,
          },
          dealerPref: {},
          preInstalledAddOns: [],
        };
      }
      vehicleDetailResponse = {
        ...vehicleDetailResponse,
        status: vehicleByVinResponse.vehicle_status,
        listing_status: vehicleByVinResponse.listing_status,
        dealer_options: vehicleByVinResponse.dealer_options,
      };

      return {
        vehicleDetail: vehicleDetailResponse,
        preInstalledAddOns: vehicleByVinResponse.dealer_options
          .filter(
            (option: { installation_mode: string; is_price_inclusive: any }) =>
              option.installation_mode === "Pre-Installed" &&
              !option.is_price_inclusive
          )
          .map(
            (option: {
              id: number;
              rv_balloon_percentage: number;
              product_name: any;
              price: any;
              is_price_inclusive: any;
              rv_balloon_value: any;
              installation_mode: any;
              rv_adder: number;
            }) => ({
              product_name: option.product_name,
              price: option.price,
              applied_price: option.price,
              rv_balloon_value: option?.is_price_inclusive
                ? 0
                : option.rv_balloon_value ?? option.rv_adder,
              option_id: option.id,
              rv_balloon_percentage:
                option.rv_balloon_percentage || option.rv_adder || 0,
              installation_mode: option.installation_mode,
              is_price_inclusive: option.is_price_inclusive,
            })
          ),
      };
    },
  });
  return {
    mutate,
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
  };
};
