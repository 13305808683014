import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Col, Row } from "antd";
import "./incentives-and-discounts.scss";
import { useUpdateDeal, useGetRebates } from "@src/hooks";
import {
  formatCurrency,
  redirectToDealerWebsite,
} from "@src/helpers/appHelper";
import {
  updateRebate,
  toggleDisclaimer,
  toggleDrawerStatus,
  toggleModalAction,
} from "@src/context/app-actions";
import { useAppContext } from "@src/context/app-context";
import { URL_BMW, BMW_BASE } from "@src/constants/routes";

import { Button, Checkbox, Drawer } from "@src/components/atoms";
import {
  BackToShopModal,
  RightRail,
  RightRailFixed,
  WelcomeBanner,
} from "@src/components/molecules";
import { IncentivesListCard } from "@src/components/molecules/incentives-list-card/incentives-list-card.component";
import { FullDetailsDrawerContent } from "@src/components/molecules/incentives-and-discounts/full-details-drawer-content/full-details-drawer-content";
import { InsentivesAndDiscountsDrawerFooter } from "@src/components/molecules/incentives-and-discounts/insentives-and-discounts-drawer-footer/insentives-and-discounts-drawer-footer.component";
import { UpdatePaymentTermsDrawerContent } from "@src/components/molecules/incentives-and-discounts/update-payment-terms-drawer-content/update-payment-terms-drawer-content";

import { FinanceLeasePricingDrawerContent } from "@src/components/molecules/incentives-and-discounts/fl-pricing-drawer-content/fl-pricing-drawer-content.component";
import { ROUTING_PATH } from "@application/application-router";
import { useGeneralContext } from "@src/context/general-context/general-context";
import { FinanceType } from "@src/constants";
import { useComputedQuotation } from "@src/hooks/useCalculateQuatation";
import {
  rebateCheck,
  rebateCheckOrder,
  formatUrl,
  generatePayload,
  getLinkClickPayload,
} from "@src/helpers";
import { IRebate } from "@src/types";
import { useAdobeDatalayerContext } from "@application/application-data-layer";
import { DisclaimersType } from "@src/constants/enum";
import Placeholder from "../../../../shared/assets/img/placeholder.png";

export const IncentivesAndDiscounts = () => {
  const { addEvent } = useAdobeDatalayerContext();
  const navigate = useNavigate();
  const [URLSearchParams] = useSearchParams();
  const {
    contextState: { softcredit, nocart },
    setContextState,
  } = useGeneralContext();
  const estimationAddedprop = URLSearchParams.get("estimationadded");
  const offerErrorParam = URLSearchParams.get("offererror");
  const attemptsErrorprop = URLSearchParams.get("attempterror");
  const softcreditprop = URLSearchParams.get("softcredit");
  const nocartprop = URLSearchParams.get("nocart");

  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const {
    ui: {
      toggles: {
        drawer: { isOpen: isDrawerToggle },
        modal: { isOpen: isBackToShopModalOpen },
      },
    },
    order,
    configuration: { urlOrigin },
    tenant: { company_id },
    dealer: {
      rebates,
      website,
      dealer_address: { state_id },
      id,
      dealer_name,
    },
    user: { isUserLoggedIn },
  } = appState;
  const { finance_type } = order;

  const [isDrawerEnable, setDrawerenable] = useState(false);
  const [rebateDetails, setRebateDetails] = useState<IRebate>();
  const { mutate: getRebates } = useGetRebates();
  const { dealRefNumber } = useParams();
  const { mutate: updateOrder, isPending } = useUpdateDeal();
  const userCredentials = JSON.parse(localStorage.getItem("customer") || "{}");
  const url = formatUrl(website);
  const isDisclaimerAgreed = JSON.parse(
    localStorage.getItem("incentives-discounts-disclaimer") || "false"
  );
  const { setOrderValues } = useComputedQuotation();
  const toggleSignIn = () => {
    addEvent("linkClick", getLinkClickPayload("sign in to save"));
    if (!isDisclaimerAgreed) {
      appDispatch(
        toggleDisclaimer({
          name: DisclaimersType.INCENTIVE_DISCOUNT,
          isOpen: true,
          openAt: new Date(),
        })
      );
      return;
    } else {
      setDrawerenable(!isDrawerEnable);
    }
  };

  const toggleDrawer = (rebate?) => {
    if (rebate) setRebateDetails(rebate);
    setDrawerVisible(!isDrawerVisible);
  };
  const toggleFLPricingDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = (tagName?: string) => {
    if (tagName) {
      addEvent("linkClick", getLinkClickPayload(tagName));
    }
    setDrawerVisible(false);
    setIsDrawerOpen(false);
    setDrawerenable(false);
    appDispatch(
      toggleDrawerStatus({ name: "incentives-and-discount", isOpen: false })
    );
    localStorage.setItem("redirectToDealerWebsite", "false");
  };
  const handleIncentiveClick = (rebate) => {
    const rebateAmount = parseFloat(rebate.rebate_amount);
    const selected = order.order_rebates.some((rebateOrder) =>
      rebateOrder.rebate_id
        ? rebateOrder.rebate_id === rebate.id
        : rebateOrder.id === rebate.id
    );
    if (!selected) {
      setOrderValues({
        ...order,
        order_rebates: Array.isArray(order.order_rebates)
          ? [...order.order_rebates, rebate]
          : [...rebate],
        rebate: order.rebate + rebateAmount,
      });
    }
    toggleDrawer();
  };

  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  useEffect(() => {
    if (isBackToShopModalOpen) {
      setIsModalOpen(isBackToShopModalOpen);
    }
  }, [isBackToShopModalOpen]);

  useEffect(() => {
    if (!isModalOpen) {
      appDispatch(
        toggleModalAction({ name: "back-to-shop-modal", isOpen: false })
      );
    }
  }, [isModalOpen]);
  const selected = order.order_rebates.some((rebateOrder) =>
    rebateOrder.rebate_id
      ? rebateOrder.rebate_id === rebateDetails?.id
      : rebateOrder.id === rebateDetails?.id
  );
  const saveOrder = () => {
    if (dealRefNumber) {
      const rebateFilter = rebateCheckOrder(order.order_rebates);
      const ChangesRebate = {
        ...order,
        order_rebates: rebateFilter,
      };
      updateOrder(
        { ...ChangesRebate },
        {
          onSuccess: () => {
            navigate(`${ROUTING_PATH.TRADE_IN_PAGE}`);
          },
        }
      );
    } else {
      navigate(`${ROUTING_PATH.TRADE_IN_PAGE}`);
    }
  };
  useEffect(() => {
    if (dealRefNumber) {
      getRebates(
        {
          companyId: company_id,
          applicableStates: state_id,
          dealType: "Financing",
        },
        {
          onSuccess: (rebatesCheck: any) => {
            const checkRebate = rebateCheck(rebatesCheck.result);
            appDispatch(updateRebate(checkRebate));
          },
        }
      );
    }
    if (state_id && finance_type) {
      getRebates(
        {
          companyId: company_id,
          applicableStates: state_id,
          financeType: finance_type,
        },
        {
          onSuccess: (rebatesCheck: any) => {
            const checkRebate = rebateCheck(rebatesCheck.result);
            appDispatch(updateRebate(checkRebate));
          },
        }
      );
    }
    setContextState({
      estimationAdded: estimationAddedprop === "true",
      isauthenticated: isUserLoggedIn,
      offerErrorParam: offerErrorParam === "true",
      attemptsError: attemptsErrorprop === "true",
      softcredit: softcreditprop === "true",
      nocart: nocartprop === "true",
      financetype: finance_type,
    });
  }, [state_id, finance_type]);

  useEffect(() => {
    if (order?.contract_term && id) {
      addEvent(
        "pageLoad",
        generatePayload(
          "bmwusaretail:buildmydeal:incentives",
          "incentives",
          isUserLoggedIn,
          { dealer_name, id },
          order
        )
      );
    }
  }, [order, dealer_name]);

  const handleRebates = (rebate, selected) => {
    const rebateAmount = parseFloat(rebate.rebate_amount);
    if (selected) {
      setOrderValues({
        ...order,
        order_rebates: Array.isArray(order.order_rebates)
          ? [...order.order_rebates, rebate]
          : [...rebate],
        rebate: order.rebate + rebateAmount,
      });
    } else {
      setOrderValues({
        ...order,
        order_rebates: order.order_rebates.filter((r) =>
          r.rebate_id ? r.rebate_id !== rebate.id : r.id !== rebate.id
        ),
        rebate: order.rebate - rebateAmount,
      });
    }
  };

  return (
    <>
      {!userCredentials?.access_token && (
        <WelcomeBanner
          hasCloseIcon={true}
          title={"Find even more savings."}
          decription={
            "Plus, unlock personalized pricing and save your progress automatically if you sign in."
          }
          actionArea={
            <>
              <Button
                text="Sign in"
                type="default"
                htmlType="button"
                onClick={() => {
                  addEvent("linkClick", getLinkClickPayload("sign in"));
                  localStorage.setItem("state", JSON.stringify(appState));
                  if (!isDisclaimerAgreed) {
                    appDispatch(
                      toggleDisclaimer({
                        name: DisclaimersType.INCENTIVE_DISCOUNT,
                        isOpen: true,
                        openAt: new Date(),
                      })
                    );
                    return;
                  } else {
                    window.location.href = BMW_BASE + "login" + URL_BMW;
                  }
                }}
              />
              <Button
                text="Register"
                htmlType="button"
                onClick={() => {
                  addEvent("linkClick", getLinkClickPayload("register"));
                  localStorage.setItem("state", JSON.stringify(appState));
                  if (!isDisclaimerAgreed) {
                    appDispatch(
                      toggleDisclaimer({
                        name: DisclaimersType.INCENTIVE_DISCOUNT,
                        isOpen: true,
                        openAt: new Date(),
                      })
                    );
                    return;
                  } else {
                    window.location.href = BMW_BASE + "register" + URL_BMW;
                  }
                }}
              />
            </>
          }
        />
      )}
      <div className="incentives-and-discounts-wrap">
        <div className="bmw-container">
          <Row gutter={{ xs: 16, sm: 16, md: 24 }}>
            <Col xs={24} sm={24} md={24} lg={16}>
              <div className="incentives-discounts-left-content">
                <h3 className="incentives-discounts--title">
                  <span className="text--body-2 label">Build my deal</span>
                  Incentives & discounts
                </h3>
                <div className="incentives-discounts--list_wrap">
                  <div className="incentives-discounts--two_grid_sm">
                    {Object.keys(rebates).map((key) => {
                      const rebate = rebates[key];
                      const selected = order.order_rebates.some((rebateOrder) =>
                        rebateOrder.rebate_id
                          ? rebateOrder.rebate_id === rebate.id
                          : rebateOrder.id === rebate.id
                      );
                      return (
                        <IncentivesListCard
                          key={key}
                          image={
                            rebate.rebate_images.length
                              ? rebate.rebate_images[0]?.location
                              : Placeholder
                          }
                          title={rebate.rebate_name}
                          subTitle={
                            <div
                              dangerouslySetInnerHTML={{
                                __html: rebate.rebate_description,
                              }}
                            ></div>
                          }
                          price={
                            <h2 className="text--body-focus-price-1">
                              {formatCurrency(rebate.rebate_amount)}
                            </h2>
                          }
                          hasCheckbox={
                            <Checkbox
                              id={`incentives-discounts--chk${key}`}
                              label="Select"
                              htmlFor={`incentives-discounts--chk${key}`}
                              isCompact
                              onChange={(e) => handleRebates(rebate, e)}
                              checked={selected}
                            />
                          }
                          actionsArea={
                            <Button
                              htmlType={"button"}
                              text="Full details"
                              linkButton
                              onClick={() => {
                                addEvent(
                                  "linkClick",
                                  getLinkClickPayload("full details")
                                );
                                toggleDrawer(rebate);
                              }}
                            />
                          }
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="incentives-discounts--ctas">
                  <div className="incentives-discounts--ctas_flexible">
                    {order.order_rebates.length ? (
                      <div>
                        <Button
                          htmlType={"button"}
                          text="Continue"
                          type="primary"
                          fixed
                          fullWidth
                          onClick={() => saveOrder()}
                        />
                      </div>
                    ) : (
                      <div>
                        <Button
                          htmlType={"button"}
                          text="Continue without incentive"
                          type="primary"
                          fixed
                          fullWidth
                          onClick={() => {
                            navigate(`${ROUTING_PATH.TRADE_IN_PAGE}`),
                              addEvent(
                                "linkClick",
                                getLinkClickPayload(
                                  "continue without incentives"
                                )
                              );
                          }}
                        />
                      </div>
                    )}
                    {!userCredentials.access_token && (
                      <div>
                        <Button
                          htmlType={"button"}
                          text="Sign in to save"
                          fullWidth
                          onClick={toggleSignIn}
                        />
                      </div>
                    )}
                  </div>
                  <p className="text--disclaimer text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id
                    est laborum.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <div className="address-right-content">
                <div className="address-right-content__inner">
                  <RightRail
                    hasFinance={
                      typeof finance_type === "string" &&
                      finance_type.toLowerCase() ===
                        FinanceType.FINANCE?.toLowerCase()
                    }
                    financeSoftCredit={
                      finance_type === FinanceType.FINANCE && softcredit
                    }
                    financeNoCart={
                      finance_type === FinanceType.FINANCE && nocart
                    }
                    hasLease={
                      typeof finance_type === "string" &&
                      finance_type.toLowerCase() ===
                        FinanceType.LEASE?.toLowerCase()
                    }
                    leaseSoftCredit={
                      finance_type === FinanceType.LEASE && softcredit
                    }
                    leaseNoCart={finance_type === FinanceType.LEASE && nocart}
                    cashAuth={
                      finance_type === FinanceType.CASH &&
                      userCredentials?.access_token
                    }
                    cashUnAuth={
                      finance_type === FinanceType.CASH &&
                      !userCredentials?.access_token
                    }
                    cashNoCart={finance_type === FinanceType.CASH && nocart}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <RightRailFixed hasFinance />
      </div>
      <div className="incentives-and-discount-drawer">
        <Drawer
          width={336}
          open={isDrawerEnable || isDrawerToggle}
          onClose={() => closeDrawer()}
          maskClosable={true}
          keyboard={true}
          content={
            <>
              <FinanceLeasePricingDrawerContent />
            </>
          }
          footer={
            <div className="fl-pricing-footer-wrap">
              <InsentivesAndDiscountsDrawerFooter
                price={
                  <>
                    <small className="text--disclaimer">Est. payments</small>
                    <h2 className="text--body-focus-price-1">
                      ${order.rebate}
                      <span className="text--body-1">/month</span>{" "}
                    </h2>
                  </>
                }
              />
            </div>
          }
        />
        <Drawer
          width={336}
          open={isDrawerOpen}
          onClose={() => closeDrawer()}
          maskClosable={true}
          keyboard={true}
          content={
            <>
              <FullDetailsDrawerContent rebate={""} />
            </>
          }
          footer={
            <>
              <InsentivesAndDiscountsDrawerFooter
                price={
                  <>
                    <h2 className="text--body-focus-price-1">
                      $X,XXX <span className="text--body-1">totals credit</span>
                    </h2>
                  </>
                }
                footerActions={
                  <Button
                    htmlType={"button"}
                    text="Add to deal"
                    type="default"
                    onClick={toggleDrawer}
                  />
                }
              />
            </>
          }
        />
        <Drawer
          width={336}
          open={isDrawerVisible}
          onClose={() => {
            closeDrawer("exit full details sheet");
          }}
          maskClosable={true}
          keyboard={true}
          content={
            <>
              <FullDetailsDrawerContent rebate={rebateDetails} />
            </>
          }
          footer={
            <>
              <InsentivesAndDiscountsDrawerFooter
                price={
                  <>
                    <h2 className="text--body-focus-price-1">
                      {formatCurrency(rebateDetails?.rebate_amount)}{" "}
                      <span className="text--body-1">total credit</span>
                    </h2>
                  </>
                }
                footerActions={
                  <Button
                    htmlType={"button"}
                    text="Add to deal"
                    disabled={selected}
                    type="default"
                    onClick={() => {
                      handleIncentiveClick(rebateDetails);

                      addEvent("linkClick", getLinkClickPayload("add to deal"));
                      toggleDrawer();
                    }}
                  />
                }
              />
            </>
          }
        />
        <div className="update-payment-terms-drawer-main">
          <Drawer
            width={336}
            open={false}
            maskClosable={true}
            keyboard={true}
            content={
              <>
                <UpdatePaymentTermsDrawerContent />

                {/* <CheckoutPricingDetailsDrawerContent /> */}
              </>
            }
            footer={
              <>
                <InsentivesAndDiscountsDrawerFooter
                  price={
                    <>
                      <small className="text--disclaimer">Est. payments</small>
                      {/* <small className="text--disclaimer">Cash offer</small> */}
                      <h2 className="text--body-focus-price-1">
                        $X,XXX
                        <span className="text--body-1">/month</span>{" "}
                      </h2>
                    </>
                  }
                  footerActions={
                    <>
                      <Button
                        htmlType={"button"}
                        text="Save & close"
                        type="default"
                      />
                    </>
                  }
                />
              </>
            }
          />
        </div>

        <BackToShopModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isAuthenticated={userCredentials?.access_token}
          handleSaveAndExits={() => {
            if (userCredentials?.access_token) {
              const rebateFilter = rebateCheckOrder(order.order_rebates);
              const ChangesRebate = {
                ...order,
                order_rebates: rebateFilter,
              };
              updateOrder(
                { ...ChangesRebate },
                {
                  onSuccess: () => {
                    redirectToDealerWebsite(website);
                  },
                }
              );
            } else {
              appDispatch(
                toggleDrawerStatus({
                  name: "incentives-and-discount",
                  isOpen: true,
                })
              );
            }
            setIsModalOpen(false);
          }}
          handleExitWithoutSaving={() => {
            setIsModalOpen(false);
            redirectToDealerWebsite(website);
          }}
        />
      </div>
    </>
  );
};
