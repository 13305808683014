import { Radio } from "@src/components/atoms";
import "./address-type-selector.scss";

import { Controller } from "react-hook-form";
export const AddressTypeSelector = ({ control }) => {
  return (
    <>
      <div className="type-selector-wrap">
        <p className="text--body-2 financial-residence-text-two">
          Is this a residence or business address?
        </p>
        <Controller
          name="address_type"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div className="type-selector-wrap--radio-container">
              <label htmlFor="residence-address">
                <Radio
                  label="Residence"
                  onChange={() => {
                    field.onChange("Residence");
                  }}
                  checked={field.value === "Residence"}
                  name="addressType"
                  htmlFor="residence-address"
                  error={!!error}
                  id="residence-address"
                />
              </label>
              <label htmlFor="business-address">
                <Radio
                  label="Business"
                  onChange={() => {
                    field.onChange("Business");
                  }}
                  error={!!error}
                  checked={field.value === "Business"}
                  name="addressType"
                  htmlFor="business-address"
                  id="business-address"
                />
              </label>
            </div>
          )}
        />
      </div>
      <hr className="checkout-hr" />
    </>
  );
};
