import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import {
  DMS_BASE_URL,
  CONFIG_BASE_URL,
  DOMAIN,
  INSURANCE_PROVIDER,
  EP_ADD_INSURANCE_DETAILS,
  EP_UPDATE_INSURANCE_DETAILS,
  DEAL_INFO,
  EP_SUBMIT_ORDER,
  DEFAULT_LENDER_CONFIGURATION,
  DMS_FRAUD_CHECK,
  LENDER_MULTIPLE,
  SAVE_DEAL,
  UPDATE_DEAL,
  REBATES_FILTER,
  DELETE_CUSTOMER_FROM_DEAL,
  INTEGRATION_BY_PROVIDER_NAME,
  APPROVE_ORDER_BY_LENDER,
  CONFIG_VEHICLE_DETAIL_BY_VIN,
  GET_ACCESSORIES_BY_MODEL,
  GET_FNI_MONTHLY_PAYMENT_IMAPACT,
  CONFIG_BASE_URL_FEAT,
} from "../constants/routes";
import { http } from "../utilities";
import { AppError } from "single-spa";

export const useGetCompanyDomain = (): {
  data: any | undefined;
  mutate: UseMutateFunction<any, AppError, { origin_domain: string }>;
  error: string | undefined;
} => {
  const { data, mutate, error } = useMutation<
    any,
    AppError,
    { origin_domain: string }
  >({
    mutationFn: async ({ origin_domain }) => {
      const response = await http(CONFIG_BASE_URL).get(
        `${DOMAIN}/${origin_domain}`
      );
      return response.data;
    },
  });
  return { data, mutate, error: error?.message };
};

export const useUpdateOrderStatusByLender = (): {
  data: any | undefined;
  mutate: UseMutateFunction<
    any,
    AppError,
    { referenceNumber; lenderId; orderStatus }
  >;
  error: string | undefined;
} => {
  const { data, mutate, error } = useMutation<
    any,
    AppError,
    { referenceNumber; lenderId; orderStatus }
  >({
    mutationFn: async ({ referenceNumber, lenderId, orderStatus }) => {
      return await http(DMS_BASE_URL)
        .patch(
          `${APPROVE_ORDER_BY_LENDER}${referenceNumber}/${lenderId}?order_status=${orderStatus}`
        )
        .then((res) => res.data);
    },
  });
  return { data, mutate, error: error?.message };
};
export const useGetDefaultLender = (): {
  data: any | undefined;
  mutate: UseMutateFunction<any, AppError, { company_id: any }>;
  error: string | undefined;
  isSuccess: boolean;
} => {
  const { data, isSuccess, mutate, error } = useMutation<
    any,
    AppError,
    { company_id: any }
  >({
    mutationFn: async ({ company_id }) => {
      const response = await http(CONFIG_BASE_URL).get(
        `${DEFAULT_LENDER_CONFIGURATION}`,
        {
          headers: { Company_id: company_id.toString() },
        }
      );
      return response.data;
    },
    onError: (error: AppError) => {
      console.error("Error fetching default lender:", error);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};

export const useOrderSubmit = (): any => {
  const { data, isPending, mutate } = useMutation<any, any>({
    mutationFn: async (body: any) => {
      return http(DMS_BASE_URL).post(
        `${EP_SUBMIT_ORDER}?reference_number=${body?.referenceNumber}`,
        body
      );
    },
  });
  return { data, isPending, mutate };
};
export const useSaveDeal = (): any => {
  const { data, isPending, mutate } = useMutation<any, any>({
    mutationFn: async (body: any) => {
      return http(DMS_BASE_URL)
        .post(SAVE_DEAL, body)
        .then((res) => res.data);
    },
  });
  return { data, isPending, mutate };
};

export const useGetAddons = (): any => {
  const { data, isPending, mutate } = useMutation<any, any>({
    mutationFn: async (body: any) => {
      return http(CONFIG_BASE_URL)
        .get(
          `${GET_ACCESSORIES_BY_MODEL}?dealer_code=${body.dealerCode}&model_name=${body.modelName}`
        )
        .then((res) => res.data);
    },
  });
  return { data, isPending, mutate };
};

export const useUpdateDeal = (): any => {
  const { data, isPending, mutate } = useMutation<any, any>({
    mutationFn: async (body: any) => {
      return http(DMS_BASE_URL)
        .patch(UPDATE_DEAL + body.identifier, body)
        .then((res) => res.data);
    },
  });
  return { data, isPending, mutate };
};
export const useGetVehicle = (): any => {
  const { data, isPending, mutate } = useMutation<any, any>({
    mutationFn: async (body: any) => {
      return http(CONFIG_BASE_URL)
        .get(`${CONFIG_VEHICLE_DETAIL_BY_VIN}${body.dealerCode}/${body.vin}`, {
          headers: { Company_id: body.company_id.toString() },
        })
        .then((res) => res.data);
    },
  });
  return { data, isPending, mutate };
};
export const useDeleteCustomerFromDeal = (): {
  mutateAsync: UseMutateFunction<
    any,
    { detail: { message: string } },
    {
      orderId: number;
      customerId: number;
    }
  >;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutateAsync, isPending, error } = useMutation<
    any,
    { detail: { message: string } },
    {
      orderId: number;
      customerId: number;
    }
  >({
    mutationFn: async (body) => {
      return await http(DMS_BASE_URL).delete(
        `${DELETE_CUSTOMER_FROM_DEAL}${body.orderId}/${body.customerId}`
      );
    },
  });
  return {
    mutateAsync,
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
  };
};
export const useGetFraudCheck = (): any => {
  const { data, isPending, mutate } = useMutation<any, any>({
    mutationFn: async (body: any) => {
      return http(DMS_BASE_URL)
        .get(
          `${DMS_FRAUD_CHECK}?order_reference_id=${body?.referenceNumber}&customer_reference_id=${body.customer_reference_id}`
        )
        .then((res) => res.data);
    },
  });
  return { data, isPending, mutate };
};

export const useGetInsuranceProvider = (): {
  data: any | undefined;
  mutate: UseMutateFunction<any, AppError, { company_id: any }>;
  error: string | undefined;
  isSuccess: boolean;
} => {
  const { data, isSuccess, mutate, error } = useMutation<
    any,
    AppError,
    { company_id: any }
  >({
    mutationFn: async ({ company_id }) => {
      const response = await http(CONFIG_BASE_URL).get(
        `${INSURANCE_PROVIDER}`,
        {
          headers: { Company_id: company_id.toString() },
        }
      );
      return response.data;
    },
    onError: (error: AppError) => {
      console.error("Error fetching default lender:", error);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};
export const useGetOrderInfo = (): {
  data: any | undefined;
  mutate: UseMutateFunction<any, AppError, any>;
  error: string | undefined;
  isSuccess: boolean;
} => {
  const { data, isSuccess, mutate, error } = useMutation<any, AppError, any>({
    mutationFn: async (dealRefNumber) => {
      return await http(DMS_BASE_URL)
        .get(`${DEAL_INFO}/${dealRefNumber}`)
        .then((res) => res.data);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};

export const useGetInsurance = () => {
  const { data, isPending, mutate } = useMutation<any, any, any>({
    mutationFn: async (body: any) => {
      return http(DMS_BASE_URL).post(`${EP_ADD_INSURANCE_DETAILS}`, body);
    },
  });
  return { data, isPending, mutate };
};

export const useUpdateInsurance = () => {
  const { data, isPending, mutate } = useMutation<any, any, any>({
    mutationFn: async (body: any) => {
      return http(DMS_BASE_URL).patch(
        `${EP_UPDATE_INSURANCE_DETAILS}?reference_id=${body.reference_id}&order_reference_id=${body.order_reference_id}`,
        body
      );
    },
  });
  return { data, isPending, mutate };
};

export const useGetLenders = (): any => {
  const { data, isSuccess, mutate, error } = useMutation<any, AppError>({
    mutationFn: async (body: any) => {
      return await http(CONFIG_BASE_URL)
        .get(
          `${LENDER_MULTIPLE}/${body.dealerCode}?finance_type=${body.finance_type}`
        )
        .then((res) => res.data);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};

export const useGetRebates = (): {
  isSuccess: boolean;
  mutate: UseMutateFunction<
    any,
    { detail: { message: string } },
    {
      companyId: number;
      applicableStates?: number;
      dealType?: string;
      financeType?: string;
    }
  >;
  error: string | undefined;
} => {
  const { isSuccess, mutate, error } = useMutation<
    any,
    { detail: { message: string } },
    {
      companyId: number;
      applicableStates?: number;
      dealType?: string;
      financeType?: string;
    }
  >({
    mutationFn: async (body) => {
      const queryParams = new URLSearchParams({
        page_number: "0",
        page_size: "100",
        ...(body.applicableStates && {
          applicable_states: String(body.applicableStates),
        }),
        ...(body.dealType && { deal_type: body.dealType }),
        ...(body.financeType && { finance_type: body.financeType }),
      }).toString();
      return await http(CONFIG_BASE_URL, { company_id: body.companyId })
        .get(`${REBATES_FILTER}?${queryParams}`)
        .then((res) => res.data);
    },
  });

  return {
    isSuccess,
    mutate,
    error: error && error.detail ? error.detail.message : undefined,
  };
};

export const useGetMonthlyImpact = (): any => {
  const { data, isSuccess, mutate, error } = useMutation<any, AppError>({
    mutationFn: async (body: any) => {
      return await http(DMS_BASE_URL)
        .post(`${GET_FNI_MONTHLY_PAYMENT_IMAPACT}`, body)
        .then((res) => res.data);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};
