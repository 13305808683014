import { decodeToken } from "react-jwt";

export const useExtractAndDecodeCode = () => {
  const url = window.location.href;
  const fragment = url.split("#")[1];
  const change_url = url.split("#")[0];

  if (fragment) {
    const params = new URLSearchParams(fragment);
    if (params.has("code")) {
      const code = params.get("id_token");
      if (code) {
        const decoded = decodeToken(code);
        localStorage.setItem("customer", JSON.stringify(decoded));
        const domain = localStorage.getItem("window");
        if (domain) {
          //will remove vin and append dealRefNumber
          window.location.replace(domain);
        }
        return decoded;
      }
    }
  } else {
    localStorage.setItem("window", change_url);
  }

  return null;
};
