import "./multi-swatch-card.scss";
import { MultiSwatchCardProps } from "./multi-swatch-card-props";
import clsx from "clsx";
import { MultiSwatchCircles } from "@atoms/multi-swatch-circles/multi-swatch-circles";
import SwatchOne from "../../../shared/assets/img/multi-swatch-circle__one.png";
import SwatchTwo from "../../../shared/assets/img/multi-swatch-circle__two.png";
import SwatchThree from "../../../shared/assets/img/multi-swatch-circle__three.png";
import { useAppContext } from "@src/context/app-context";

export const MultiSwatchCard = ({
  className,
  isDisabledCard,
  toggleDrawerPre,
}: MultiSwatchCardProps) => {
  const {
    state: {
      vehicle: { preInstalled },
      order: {
        order_asset: { model, make, year },
      },
    },
  } = useAppContext();
  const imageLocations = Array.isArray(preInstalled)
    ? preInstalled.map((item) => item.image?.[0]?.location || "")
    : [];
  return (
    <div
      className={clsx(className, {
        "multi-swatch-card": true,
        is_row_disabled: isDisabledCard,
      })}
    >
      <div className="">
        <MultiSwatchCircles images={imageLocations} maxVisible={2} />
      </div>
      <div>
        <div className="text--body-2">
          This {year} {make} {model} comes with {preInstalled.length}{" "}
          pre-installed accessories from BMW of Manhattan.
        </div>

        <div
          className="text--link-2 text-underline "
          role="button"
          tabIndex={0}
          onClick={toggleDrawerPre}
        >
          See what's included
        </div>
      </div>
    </div>
  );
};
