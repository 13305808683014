import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { http } from "../utilities";
import { AppError } from "single-spa";
import {
  CONFIG_BASE_URL,
  DMS_BASE_URL,
  EP_DMS_GET_STATES,
  DEALERS,
  CONFIG_DEALER_PROFILE,
  GET_SETUP_DATA,
  GET_DISCLAIMER_BY_NAME,
  THEME,
  DISCLAIMER_AGREEMENT,
  DEALER_PREFERENCE_BY_DEALER_CODE,
  GET_DEALER_FEES,
} from "../constants/routes";
import { State } from "@src/types";
import { AxiosResponse } from "axios";

export const useGetByDealerId = (): any => {
  const { data, isSuccess, mutate, error } = useMutation<any, AppError>({
    mutationFn: async (body: any) => {
      return await http(CONFIG_BASE_URL)
        .get<{ dealer_code: string }>(`${DEALERS}/${body.dealerId}`, {
          headers: { Company_id: body.company_id.toString() },
        })
        .then(
          async (
            res: AxiosResponse<{
              dealer_code: string;
              contact_person_email: string;
            }>
          ) => {
            const response = await http(CONFIG_BASE_URL).get(
              `${CONFIG_DEALER_PROFILE}/${res.data.dealer_code}`
            );
            const dealer_response = {
              ...res.data,
              dealer_email: res.data.contact_person_email,
            };
            return { ...dealer_response, ...response.data };
          }
        );
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};
export const useGetDealerProfile = (): {
  mutate: UseMutateFunction<any, AppError, { dealerCode: string }>;
  error?: string;
} => {
  const { mutate, error } = useMutation<any, AppError, { dealerCode: string }>({
    mutationFn: async ({ dealerCode }) => {
      return await http(CONFIG_BASE_URL)
        .get(`${CONFIG_DEALER_PROFILE}/${dealerCode}`)
        .then((res) => res.data);
    },
  });
  return { mutate, error: error?.message };
};

export const useGetDealerPreference = (): {
  mutate: UseMutateFunction<
    any,
    AppError,
    { dealerCode: string; companyId: string }
  >;
  error?: string;
} => {
  const { mutate, error } = useMutation<
    any,
    AppError,
    { dealerCode: string; companyId: string }
  >({
    mutationFn: async ({ dealerCode, companyId }) => {
      return await http(CONFIG_BASE_URL)
        .get(`${DEALER_PREFERENCE_BY_DEALER_CODE}/${dealerCode}`, {
          headers: { company_id: companyId.toString() },
        })
        .then((res) => res.data);
    },
  });

  return { mutate, error: error?.message };
};

export const useGetTheme = (): {
  mutate: UseMutateFunction<any, AppError, { email: string }>;
  error?: string;
} => {
  const { mutate, error } = useMutation<any, AppError, { email: string }>({
    mutationFn: async ({ email }) => {
      const response = await http(CONFIG_BASE_URL).get(
        `${THEME}?user_email=${email}`
      );
      return response.data;
    },
  });
  return { mutate, error: error?.message };
};

export const useGetStates = (): any => {
  const { mutateAsync, error } = useMutation<State[], AppError>({
    mutationFn: async () =>
      await http(CONFIG_BASE_URL)
        .get(EP_DMS_GET_STATES)
        .then((res) => res.data),
  });
  return { mutateAsync, error: error?.message };
};

export const useGetSetupData = (): any => {
  const { mutateAsync, error } = useMutation<State[], AppError>({
    mutationFn: async () => {
      return await http(DMS_BASE_URL)
        .get(
          `${GET_SETUP_DATA}?setup_data_indicators=credit-ratings&setup_data_indicators=contract-terms&setup_data_indicators=contract-allowed-annum-mileage`
        )
        .then((res) => res.data);
    },
  });
  return { mutateAsync, error: error?.message };
};

export const useAddCustomerConsent = (): {
  mutate: UseMutateFunction<any, AppError, any>;
  error: string | undefined;
} => {
  const { mutate, error } = useMutation<any, AppError, any>({
    mutationFn: async (body) => {
      const response = await http(DMS_BASE_URL).post(
        DISCLAIMER_AGREEMENT,
        body
      );
      return response.data;
    },
  });
  return { mutate, error: error?.message };
};

export const useGetDisclaimerByName = () => {
  const { isPending, mutateAsync, error } = useMutation<
    State[],
    AppError,
    { disclaimerName: string }
  >({
    mutationFn: async ({ disclaimerName }) => {
      return http(DMS_BASE_URL)
        .get(`${GET_DISCLAIMER_BY_NAME}/${disclaimerName}`)
        .then((res) => res.data);
    },
  });

  return { isPending, mutateAsync, error: error?.message };
};

export const useGetDealerFees = (): {
  isPending: boolean;
  mutateAsync: UseMutateFunction<
    any,
    AppError,
    { dealerCode: string; stateCode: string }
  >;
  error: string | undefined;
} => {
  const { isPending, mutateAsync, error } = useMutation<
    State[],
    AppError,
    { dealerCode: string; stateCode: string }
  >({
    mutationFn: async ({ dealerCode, stateCode }) => {
      const orderFees = await http(CONFIG_BASE_URL)
        .get(`${GET_DEALER_FEES}/${dealerCode}?state_code=${stateCode}`)
        .then((res) => res.data);

      const mappedFees: any = [];
      orderFees.forEach((fee: any) => {
        if (fee.is_active)
          mappedFees.push({
            dealer_fee_id: fee.id,
            applicable_finance_type: fee.applicable_finance_type,
            dealer_id: fee.dealer_id,
            fee_name: fee.fee_name || fee?.dealer_fee_name,
            default_amount: fee.default_amount || 0,
            fee_handling: fee.fee_handling || "Other",
            taxable: fee.taxable,
            event: fee.event,
            calculation_type: fee.calculation_type,
            multiplication_factor: fee.multiplication_factor,
          });
      });
      return mappedFees;
    },
  });

  return { isPending, mutateAsync, error: error?.message };
};
