import { Button } from "@src/components/atoms";
import "./menu.scss";
import { useAppContext } from "@src/context/app-context";
import { useLocation } from "react-router-dom";
import { ShowBackButtonOnGivenURLs } from "@src/constants/enum";
import { BackToShopModal } from "@src/components/molecules";
import { useState } from "react";
import {
  toggleDrawerStatus,
  toggleModalAction,
} from "@src/context/app-actions";
import { isValidUrl } from "@src/helpers/appHelper";

export const Menu = () => {
  const location = useLocation(); // Get the current path
  const { dispatch: appDispatch } = useAppContext();

  const shouldShowButton = ShowBackButtonOnGivenURLs.some((path) =>
    location.pathname.includes(path)
  );

  return (
    <div className={`menu ${shouldShowButton ? "with-back-btn" : ""}`}>
      <div className={`menu-desktop`}>
        <div className="menu-item-container menu-title-wrap">
          <div
            className="bmw-icon bmw-icon--before data-icon back-btn with-back-btn"
            data-icon="arrow_left"
            role="button"
            tabIndex={0}
            onClick={() => {
              localStorage.setItem("redirectToDealerWebsite", "true");
              appDispatch(
                toggleModalAction({ name: "back-to-shop-modal", isOpen: true })
              );
            }}
          />
          <p className="menu-title text--body-2 d-md-block d-none">
            Purchase Online
          </p>
        </div>
        <div className="menu-divider"></div>
        <div className="menu-item-container menu-overflow">
          <div className="menu-item">
            <Button
              htmlType="button"
              text="Apply for Credit"
              linkButton
              withIcon
              className=""
              dataIcon="checkmark"
              isIconEnd
            />
          </div>

          <div className="menu-item">
            <Button
              htmlType="button"
              text="Submit order"
              linkButton
              withIcon
              isIconEnd
              className="menu-item--active"
              dataIcon="lock_locked"
            />
          </div>
          <div className="menu-item">
            <Button
              htmlType="button"
              text="Finalize your deal"
              linkButton
              withIcon
              isIconEnd
              className=""
              dataIcon="lock_locked"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};
