import { createContext, ReactNode, useContext } from "react";

type Props = { children: ReactNode };
type dobeDataLayerType = Window & { adobeDataLayer?: any[] };
type ContextType = {
  addEvent: (eventName: string, payload: any) => void;
  data: any[];
};

const AdobeDatalayerContext = createContext<ContextType | undefined>(undefined);

// Data layer provider
export const DataLayerProvider = ({ children }: Props) => {
  const windowWithAdobeDataLayer: dobeDataLayerType = window;

  const addEvent = (eventName: string, payload: any) => {
    if (!windowWithAdobeDataLayer.adobeDataLayer) {
      return;
    }

    windowWithAdobeDataLayer.adobeDataLayer.push({
      event: eventName,
      data: payload,
    });
  };

  return (
    <AdobeDatalayerContext.Provider
      value={{ addEvent, data: windowWithAdobeDataLayer.adobeDataLayer || [] }}
    >
      {children}
    </AdobeDatalayerContext.Provider>
  );
};

// Custom hook for using the context
export const useAdobeDatalayerContext = () => {
  const context = useContext(AdobeDatalayerContext);
  if (!context) {
    throw new Error(
      "useAdobeDatalayerContext must be used within an AdobeDatalayerContext provider"
    );
  }
  return context;
};
