import "./accessories-list-card-drawer-content.scss";
import { Button, Image } from "@src/components/atoms";

import Image1 from "../../../../shared/assets/img/accessories-list-card-drawer-content-img.png";
import { formatCurrency, formatUrl } from "@src/helpers";
import { useAppContext } from "@src/context/app-context";

export const AccessoriesListCardDrawerContent = ({ accessory }) => {
  return (
    <>
      <div className="accessories-full-details">
        <div className="accessories-full-details__left">
          <div className="accessories-full-details__image">
            <Image
              src={accessory.image.length ? accessory.image[0].location : ""}
              alt="image"
              aspectRatio="1:1"
            />
          </div>
        </div>
        <div className="accessories-full-details__right">
          <div className="accessories-full-details__right--inner">
            <div className="text--subsection-1">{accessory.product_name}</div>
            <div className="accessories-full-details__right--price-detail">
              <div className="accessories-full-details__right--price-section">
                <span className="card-select-default-checked text--body-2">
                  Monthly cost:
                </span>
                <div className="text--promo-focus-price">
                  {formatCurrency(accessory.monthly_impact)}{" "}
                  <span className="text--body-1">/month</span>{" "}
                </div>
                <p className="text--label">
                  {formatCurrency(accessory.price ?? 0)} total
                </p>
              </div>
              <p className="text--body-2">{accessory?.description}</p>
            </div>

            {/* <p className="text--disclaimer text-muted">
              Upgraded packages also available. Ex: (full hood, mirrors, side
              skirts).
            </p> */}
          </div>

          <div className="accessories-full-details__right--links-section">
            {accessory.video_url && (
              <Button
                htmlType={"button"}
                text="Play video"
                linkButton
                onClick={() =>
                  window.open(formatUrl(accessory.video_url), "_blank")
                }
                withIcon
                dataIcon="play"
              />
            )}

            {accessory.brochure_url && (
              <Button
                htmlType={"button"}
                text="Download brochure"
                onClick={() =>
                  window.open(formatUrl(accessory.brochure_url), "download")
                }
                linkButton
                withIcon
                dataIcon="download"
              />
            )}
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
};
