import {
  DMS_BASE_URL,
  INTEGRATION_BY_PROVIDER_NAME,
} from "@src/constants/routes";
import { http } from "@src/utilities";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";

export const useIntegrationProvider = (): {
  isPending: boolean;
  error: string | undefined;
  mutate: UseMutateFunction<
    any,
    { detail: { message: string } },
    {
      dealerCode: string;
      companyId: string;
      providerName: string;
      integrationType: string;
    }
  >;
} => {
  const { mutate, isPending, error } = useMutation<
    any,
    { detail: { message: string } },
    {
      dealerCode: string;
      companyId: string;
      providerName: string;
      integrationType: string;
    }
  >({
    mutationFn: async (body: any) => {
      return http(DMS_BASE_URL)
        .get(
          `${INTEGRATION_BY_PROVIDER_NAME}/${body.dealerCode}/${body.providerName}/${body.integrationType}`,
          {
            headers: { company_id: body.companyId },
          }
        )
        .then((res) => res.data);
    },
  });
  return {
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
    mutate,
  };
};
