import { camelCase } from "lodash";

export const getDisclaimerByType = (disclaimers: any, dealerName?: string) => {
  return disclaimers.reduce((acc, disclaimer) => {
    const disclaimerName = disclaimer.name ?? disclaimer.disclaimer_name;
    const disclaimerToReplace =
      disclaimer.description ?? disclaimer.disclaimer_details;
    const changesDisclaimer = disclaimerToReplace?.replace(
      /@dealer/g,
      `${dealerName}`
    );

    if (disclaimerName) {
      acc[camelCase(disclaimerName)] = {
        disclaimer_name: disclaimerName,
        disclaimer_version: disclaimer.version ?? disclaimer.disclaimer_version,
        changesDisclaimer,
      };
    }
    return acc;
  }, {});
};

export const rebateCheck = (rebates: any) => {
  return rebates.reduce((acc, rebate) => {
    const {
      id,
      applicable_states,
      rebate_images,
      rebate_amount,
      rebate_description,
      rebate_name,
      required_documents,
      is_active,
      eligibility_requirements,
      ...rest
    } = rebate;
    if (rebate_name && is_active) {
      acc[camelCase(rebate_name)] = {
        id,
        rebate_name,
        applicable_states,
        rebate_images,
        rebate_amount,
        rebate_description,
        required_documents,
        eligibility_requirements,
      };
    }
    return acc;
  }, {});
};

export const rebateCheckOrder = (rebates: any) => {
  let arr: any = [];

  rebates.forEach((rebate) => {
    const { id, rebate_name, rebate_images, rebate_amount, ...rest } = rebate;

    if (rebate_name) {
      arr.push({
        rebate_id: id,
        name: rebate_name,
        image_uri: rebate_images.length ? rebate_images[0]?.location : "",
        amount: rebate_amount,
      });
    }
  });

  return arr;
};

export const addOnsInstall = (addons: any) => {
  let addOn: any[] = [];
  let preInstalled: any[] = [];

  addons.forEach((addon) => {
    if (
      (addon.installation_mode === "Pre-Installed" &&
        addon.is_price_inclusive === false) ||
      addon.installation_mode === "Optional"
    ) {
      addOn.push({
        applied_price: addon.price,
        is_price_inclusive: addon.is_price_inclusive,
        rv_balloon_percentage: addon.rv_balloon_percentage ?? 0,
        rv_balloon_value: addon.rv_balloon_value ?? 0,
        product_name: addon.product_name,
        option_id: addon.id,
        description: addon.description,
        price: addon.price,
        installation_mode: addon.installation_mode,
        brochure_url: addon.brochure_url,
        video_url: addon.video_url,
        supplier: addon.supplier,
        image: addon.image,
      });
    }
    if (addon.installation_mode === "Pre-Installed") {
      preInstalled.push(addon);
    }
  });

  return { addOn, preInstalled };
};

export const ImpactPayload = (addons: any, monthlyImapct: any[]) => {
  let acc: any = [];

  addons.forEach((addon, index) => {
    acc.push({
      ...addon,
      monthly_impact: monthlyImapct[index].monthly_impact,
    });
  });

  return acc;
};
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};
