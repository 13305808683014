import { memo } from "react";
import { Step } from "@src/components/atoms/step/step.component";
import {
  RadioSelector,
  PersonalDetails,
  CoApplicantDetails,
  ApplicationDetails,
} from "@src/components/molecules";
import { useForm, SubmitHandler } from "react-hook-form";
import { MessageAlert } from "@src/components/atoms";

import "./vehicle-ownership.style.scss";
import { BasicCustomerInfo } from "@src/types/customer";
import { useCustomerUpdate, useDeleteCustomerFromDeal } from "@src/hooks";
import { useAppContext } from "@src/context/app-context";
import { filterCustomerData } from "@src/helpers/appHelper";
import { setOrderInfo, updateCustomer } from "@src/context/app-actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { basicValidator } from "@src/validators";

const VehicleOwnership = ({
  stepOneDataReceivedEvent,
  customerInfo,
  dispatch,
  state,
}: {
  customerInfo: Omit<BasicCustomerInfo, "email"> & { updated_by: string };
  dispatch;
  state;
  stepOneDataReceivedEvent: () => void;
}) => {
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(basicValidator),
    defaultValues: {
      ...customerInfo,
      date_of_birth: customerInfo.date_of_birth
        ? customerInfo.date_of_birth
        : null,
      vehicle: {
        ownership: appState.order.coapplicant_info.email
          ? "co-applicant"
          : "sole-person",
      },
    },
  });

  const singleApplicant = watch("vehicle.ownership") === "sole-person";
  const jointApplicant = watch("vehicle.ownership") === "co-applicant";
  const { mutateAsync: deleteCustomerFromDeal } = useDeleteCustomerFromDeal();
  const { mutateAsync: updateCustomerData, isPending } = useCustomerUpdate({
    reference_id: appState.user.customer.reference_id,
    dealer_code: appState.dealer.dealer_code,
  });

  const onSubmit: SubmitHandler<
    BasicCustomerInfo & { vehicle: { ownership: string } }
  > = async (values) => {
    const { vehicle, ...rest } = values;
    let updatedOrder = { ...appState.order };

    if (
      vehicle.ownership === "sole-person" &&
      appState.order.coapplicant_info?.email
    ) {
      await deleteCustomerFromDeal(
        {
          orderId: appState.order.id,
          customerId: appState.order.coapplicant_info.id,
        },
        {
          onSuccess: (customerInfo) => {
            updatedOrder = {
              ...updatedOrder,
              coapplicant_info: {
                ...appState.order.coapplicant_info,
                email: "",
                first_name: "",
              },
            };
          },
          onError: (err) => {
            console.error("Error removing co-applicant:", err);
          },
        }
      );
    }
    rest.mobile_number =
      rest.mobile_number.length < 6 ? "" : rest.mobile_number;

    await updateCustomerData(
      {
        customer_profile: rest,
        type: "",
      },
      {
        onSuccess: (customerInfo) => {
          const filterData = filterCustomerData(customerInfo);
          const customerData = { ...appState.user.customer, ...filterData };
          appDispatch(
            setOrderInfo({
              ...updatedOrder,
              customer_info: { ...appState.order.customer_info, ...rest },
            })
          );
          appDispatch(updateCustomer(customerData));
          stepOneDataReceivedEvent();
        },
        onError: (error) => {
          if (error.detail?.message?.includes("ssn")) {
            setError("ssn", {
              type: "manual",
              message: error.detail.message.split(":")[0].trim(),
            });
          }
        },
      }
    );
  };

  return (
    <div className="who-i-am">
      <div className="who-i-am section-wrapper">
        <Step />
        <RadioSelector formControl={control} />
      </div>
      {jointApplicant && (
        <>
          {appState.order.coapplicant_info.email ? (
            <>
              <ApplicationDetails
                title="Application details."
                tagLabel="Completed by primary applicant"
                tagLabelType="primary"
                innerHeading="Vehicle ownership."
                innerDescription={`This vehicle is for you and ${appState.order.coapplicant_info.first_name}. You’ll share financial responsibility and ownership of this BMW. `}
              />

              <MessageAlert
                type="success"
                title={`An invitation has been sent to  ${appState.order.coapplicant_info.first_name}.`}
                subTitle="Please note that both applicants must submit their portion before we can process your joint credit application."
                showCloseButton={false}
              />
            </>
          ) : (
            <CoApplicantDetails
              dispatch={dispatch}
              stepOneDataReceivedEvent={stepOneDataReceivedEvent}
            />
          )}
        </>
      )}

      {(singleApplicant || appState.order.coapplicant_info.email) && (
        <PersonalDetails
          formControl={control}
          errors={errors}
          showSSNValue={!!customerInfo.ssn}
          clearErrors={clearErrors}
          isPending={isPending}
          handleSubmit={handleSubmit(onSubmit)}
        />
      )}
    </div>
  );
};

export { VehicleOwnership };
