import "./product-details-drawer-content.scss";
import { Button, Image, MultiSwatchCircles } from "@src/components/atoms";
import Image1 from "../../../../shared/assets/img/accessories-list-card-drawer-content-img.png";
import { IncentivesListCard } from "../../incentives-list-card/incentives-list-card.component";
import Image2 from "../../../../shared/assets/img/sheet-protection-product-1.png";
import Image3 from "../../../../shared/assets/img/sheet-protection-product-2.png";
import Image4 from "../../../../shared/assets/img/sheet-protection-product-3.png";
import Image5 from "../../../../shared/assets/img/sheet-protection-product-4.png";
import { useAppContext } from "@src/context/app-context";
import SwatchOne from "../../../../shared/assets/img/multi-swatch-circle__one.png";
import SwatchTwo from "../../../../shared/assets/img/multi-swatch-circle__two.png";
import SwatchThree from "../../../../shared/assets/img/multi-swatch-circle__three.png";

export const ProductDetailsDrawerContentAccessories = () => {
  const {
    state: {
      vehicle: { preInstalled },
      dealer: { dealer_name },
      order: {
        contract_term,
        order_asset: { mileage },
      },
    },
  } = useAppContext();
  const imageLocations = Array.isArray(preInstalled)
    ? preInstalled.map((item) => item.image?.[0]?.location || "")
    : [];
  return (
    <>
      <div className="product-details-sheet-header">
        <div className="product-details-sheet-header__web-text">
          <div className="product-details-sheet-header__heading">
            <div className="text--h3">
              {preInstalled.length} pre-installed accessories by {dealer_name}.
            </div>
          </div>

          <div className="swatch-container">
            <MultiSwatchCircles images={imageLocations} maxVisible={3} />
          </div>
        </div>

        <div className="product-details-sheet-header__mobile-text">
          <div className="product-details-sheet-header__heading">
            <div className="text--h3">
              {preInstalled.length} pre-installed accessories by {dealer_name}.
            </div>
          </div>

          <div className="text--body-2">
            [{dealer_name}] has pre-configured this bundle with coverage terms
            of {contract_term} months / {mileage} miles. To adjust these
            settings, please{" "}
            <span className="text--link-2 text-underline ">
              contact dealer.
            </span>
          </div>
        </div>
      </div>
      <div className="product-details-sheet-protection-product__list-wrap">
        {preInstalled.map((accessory, index) =>
          accessory ? (
            <IncentivesListCard
              key={index}
              image={accessory.image.length ? accessory.image[0].location : ""}
              title={accessory.product_name}
              subTitle={
                <>
                  <p className="text--body-2">{accessory.description}</p>
                </>
              }
              className=""
            />
          ) : (
            <></>
          )
        )}
      </div>
    </>
  );
};
