import {
  CONFIG_BASE_URL,
  DMS_BASE_URL,
  FNI_MONTHLY_PAYMENT_IMAPACT,
  FNI_PRODUCTS,
  GET_FNI_BUNDLES,
  GET_RATES,
  UMS_BASE_URL,
} from "@src/constants/routes";
import { http } from "@src/utilities";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";

export const useGetFNIBundles = (): {
  isPending: boolean;
  error: string | undefined;
  mutate: UseMutateFunction<
    any,
    { detail: { message: string } },
    { dealerCode: string; companyId: string }
  >;
} => {
  const { mutate, isPending, error } = useMutation<
    any,
    { detail: { message: string } },
    { dealerCode: string; companyId: string }
  >({
    mutationFn: async (body) => {
      return http(CONFIG_BASE_URL)
        .get(
          `${GET_FNI_BUNDLES}dealer_code=${body.dealerCode}&page_number=0&page_size=5`,
          {
            headers: { company_id: body.companyId },
          }
        )
        .then((res) => res.data);
    },
  });
  return {
    mutate,
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
  };
};

export const useGetRates = (): {
  mutate: UseMutateFunction<
    any,
    { detail: { message: string } },
    { ratesPayload }
  >;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutate, isPending, error } = useMutation<
    any,
    { detail: { message: string } },
    { ratesPayload }
  >({
    mutationFn: async (ratesPayload) => {
      return await http(UMS_BASE_URL)
        .post(`${GET_RATES}`, ratesPayload)
        .then((res) => res.data);
    },
  });
  return {
    mutate,
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
  };
};

export const useGetFNIProducts = (): {
  mutate: UseMutateFunction<
    any,
    { detail: { message: string } },
    { dealerCode: string; companyId: string }
  >;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutate, isPending, error } = useMutation<
    any,
    { detail: { message: string } },
    { dealerCode: string; companyId: string }
  >({
    mutationFn: async (body) => {
      return http(CONFIG_BASE_URL)
        .get(
          `${FNI_PRODUCTS}dealer_code=${body.dealerCode}&page_number=0&page_size=1000&is_active=true`,
          {
            headers: { company_id: body.companyId },
          }
        )
        .then((res) => res.data);
    },
  });
  return {
    mutate,
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
  };
};

export const useGetMonthlyImpactOnFNIProducts = (): {
  mutate: UseMutateFunction<
    any,
    { detail: { message: string } },
    { coveragePayload }
  >;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutate, isPending, error } = useMutation<
    any,
    { detail: { message: string } },
    { coveragePayload }
  >({
    mutationFn: (coveragePayload) => {
      return http(DMS_BASE_URL)
        .post(FNI_MONTHLY_PAYMENT_IMAPACT, coveragePayload)
        .then((res) => res.data);
    },
  });
  return {
    mutate,
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
  };
};
