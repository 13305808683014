import { AppError } from "single-spa";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";

import {
  DMS_BASE_URL,
  CUSTOMER,
  UMS_BASE_URL,
  AUTH_TOKEN,
  CUSTOMER_EMAIL_CHECK,
  CREDIT_CHECK,
  CUSTOMER_SAVE_DISCLOSURE,
  GET_CUSTOMER_DISCLOSURE,
  HUBEX_BASE_URL,
  VERIFY_ADDRESS,
} from "../constants/routes";
import { http } from "../utilities";
import {
  Address,
  BasicCustomerInfo,
  Customer,
  FinancialProfile,
} from "@src/types/customer";

export const useGetCustomerByEmail = (): {
  mutate: UseMutateFunction<any, { detail: string }, { email: string }>;
  error: string | undefined;
} => {
  const { mutate, error } = useMutation<
    Customer,
    { detail: string },
    { email: string }
  >({
    mutationFn: async ({ email }) => {
      const response = await http(DMS_BASE_URL).get(
        `${CUSTOMER}?email=${email}`
      );
      return response.data;
    },
  });
  return { mutate, error: error?.detail };
};

export const useCreateCustomer = (): {
  mutate: UseMutateFunction<any, AppError, any>;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutate, isPending, error } = useMutation<Customer, AppError, any>({
    mutationFn: async (body) => {
      const response = await http(DMS_BASE_URL).patch(
        `${CUSTOMER_EMAIL_CHECK}`,
        body
      );
      return response.data;
    },
  });
  return { mutate, isPending, error: error?.message };
};

export const useGetToken = (): {
  mutate: UseMutateFunction<any, AppError, any>;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutate, isPending, error } = useMutation<any, AppError, any>({
    mutationFn: async (body: any) => {
      const response = await http(UMS_BASE_URL).post(`${AUTH_TOKEN}`, body);
      return response.data;
    },
  });
  return { mutate, isPending, error: error?.message };
};

export const useGetCustomerFinancialProfile = (): {
  mutate: UseMutateFunction<
    any,
    AppError,
    { reference_id: string; dealer_code: string }
  >;
  error: string | undefined;
} => {
  const { mutate, error } = useMutation<
    Customer,
    AppError,
    { reference_id: string; dealer_code: string }
  >({
    mutationFn: async ({ reference_id, dealer_code }) => {
      const response = await http(DMS_BASE_URL).get(
        `${CUSTOMER}/personal-finance?reference_id=${reference_id}&dealer_code=${dealer_code}`
      );
      return response.data;
    },
  });
  return { mutate, error: error?.message };
};

export const useUpdateCustomerFinancialProfile = (
  data
): {
  mutate: UseMutateFunction<
    any,
    AppError,
    FinancialProfile & { method?: string }
  >;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutate, isPending, error } = useMutation<
    Customer,
    AppError,
    FinancialProfile & { method?: string }
  >({
    mutationFn: async (payload) => {
      const { method, ...rest } = payload;
      const httpMethod = method || "PATCH";
      return await http(DMS_BASE_URL)
        [httpMethod.toLowerCase()](
          `${CUSTOMER}/personal-finance?reference_id=${data.reference_id}&dealer_code=${data.dealer_code}`,
          rest
        )
        .then((res) => res.data);
    },
  });
  return { mutate, isPending, error: error?.message };
};

export const useCustomerUpdate = (
  data
): {
  mutateAsync: UseMutateFunction<
    any,
    { detail: { message: string } },
    (
      | { customer_profile: BasicCustomerInfo }
      | { customer_profile: { first_name: string; updated_by: string } }
      | { customer_addresses: Address[] }
      | FinancialProfile
    ) & {
      type: string;
      method?: string;
      reference_id?: string;
    }
  >;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutateAsync, isPending, error } = useMutation<
    Customer,
    { detail: { message: string } },
    (
      | { customer_profile: BasicCustomerInfo }
      | { customer_profile: { first_name: string; updated_by: string } }
      | { customer_addresses: Address[] }
      | FinancialProfile
    ) & {
      type: string;
      method?: string;
      reference_id?: string;
    }
  >({
    mutationFn: async (payload) => {
      const { type, method, reference_id, ...rest } = payload;
      const httpMethod = method || "PATCH";
      return await http(DMS_BASE_URL)
        [httpMethod.toLowerCase()](
          `${CUSTOMER}${type}?reference_id=${
            reference_id ?? data.reference_id
          }&${
            data.order_id
              ? `order_id=${data.order_id}`
              : `dealer_code=${data.dealer_code}`
          }`,
          type === "/employment-info"
            ? { ...rest, reference_id: reference_id }
            : rest
        )
        .then((res) => res.data);
    },
  });
  return {
    mutateAsync,
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
  };
};

export const useGetCustomerCreditCheck = () => {
  const { data, isSuccess, mutate, error } = useMutation<any, any, any>({
    mutationFn: async (body: any) => {
      const queryParams = new URLSearchParams({
        customer_reference_id: String(body.customerReferenceId),
        dealer_code: String(body.dealCode),
      }).toString();
      const response = await http(DMS_BASE_URL).post(
        `${CREDIT_CHECK}?${queryParams}`
      );
      return response.data;
    },
  });
  return { mutate, error: error?.detail };
};

export const useUpdateCustomerDisclosure = (): {
  mutate: UseMutateFunction<any, AppError, any>;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutate, isPending, error } = useMutation<Customer, AppError, any>({
    mutationFn: async (body) => {
      const response = await http(DMS_BASE_URL).post(
        `${CUSTOMER_SAVE_DISCLOSURE}`,
        body
      );
      return response.data;
    },
  });
  return { mutate, isPending, error: error?.message };
};
export const useGetCustomerDisclosure = (): {
  mutate: UseMutateFunction<any, AppError, { reference_number: string }>;
  error: string | undefined;
} => {
  const { mutate, error } = useMutation<
    Customer,
    AppError,
    { reference_number: string }
  >({
    mutationFn: async ({ reference_number }) => {
      const response = await http(DMS_BASE_URL).get(
        `${GET_CUSTOMER_DISCLOSURE}?reference_number=${reference_number}`
      );
      return response.data;
    },
  });
  return { mutate, error: error?.message };
};

export const useVerifyAddress = (): {
  mutateAsync: UseMutateFunction<any, AppError, { zipCode: string }>;
  error: string | undefined;
} => {
  const { mutateAsync, error } = useMutation<
    any,
    AppError,
    { zipCode: string }
  >({
    mutationFn: async (zipCode) =>
      await http(HUBEX_BASE_URL)
        .get(`${VERIFY_ADDRESS}${zipCode}`)
        .then((res) => res.data),
  });
  return { mutateAsync, error: error?.message };
};
