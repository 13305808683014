import { MessageAlert } from "@src/components/atoms";
import { MaterialStatusCheck } from "./material-status-check/material-status-check.component";
import { SpouseName } from "./spouse-name/spouse-name.component";
import "./spouse-info-disclosure.scss";
import { SpouseAddress } from "./spouse-address/spouse-address.component";
import { Address } from "@src/components/organisms/credit-application/checkout/02-current-and-past-addresses";
import { SpouseCoApplicant } from "./spouse-co-applicant/spouse-co-applicant.component";
import { useEffect } from "react";

export const SpouseInfoDisclosure = ({
  control,
  watch,
  stateOptions,
  showInfoMessage,
}) => {
  const spouseAddressSameAsMailing = watch("spouseAddressSameAsMailing");
  const isMarried = watch("isMarried");
  const isSpouseCoApplicant = watch("isSpouseCoApplicant");

  return (
    <div className="spouse-info-wrap">
      {showInfoMessage && (
        <MessageAlert
          type="info"
          title="Per state laws, married Wisconsin residents must include their spouse’s information when applying for credit. If your spouse is not a co-applicant, please provide their information below. "
          showCloseButton={false}
        />
      )}
      <MaterialStatusCheck control={control} />
      <SpouseCoApplicant control={control} />
      {!isSpouseCoApplicant && isMarried && <SpouseName control={control} />}
      {!isSpouseCoApplicant && isMarried && <SpouseAddress control={control} />}
      {!spouseAddressSameAsMailing && !isSpouseCoApplicant && isMarried && (
        <div>
          <Address
            type="spouse.address"
            control={control}
            stateOptions={stateOptions}
          />
        </div>
      )}
    </div>
  );
};
