import { IApplicationState } from "@src/types";
import { ActionType } from "./app-actions";
import { initialState } from "./app-state";
import { collectionIntoSelectOptions } from "@src/helpers";

export const appReducer = (
  state: IApplicationState = initialState,
  action: { type: ActionType; payload: any }
) => {
  switch (action.type) {
    case ActionType.LOAD_TENANT_CONFIGURATIONS:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          tenantId: action.payload.id,
          slug: action.payload.slug,
          defaultRadius: action.payload.default_radius,
          mileageUnit: action.payload.mileage_unit,
          tenantName: action.payload.name,
          email: action.payload.email,
          company_id: action.payload.id,
          companyCode: action.payload.company_code,
          min_months_stay_at_address: action.payload.min_months_stay_at_address,
          currency: {
            ...state.tenant.currency,
            defaultCurrency: action.payload.default_currency,
          },
        },
      };
    case ActionType.UPDATE_ORDER_INFO: {
      return {
        ...state,
        order: action.payload,
      };
    }
    case ActionType.ORDER_DISCLAIMER: {
      return {
        ...state,
        dealer: {
          ...state.dealer,
          disclaimers_attach: action.payload,
        },
      };
    }
    case ActionType.STORE_SETUP_DATA:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          setupData: action.payload,
        },
      };
    case ActionType.SOCURE:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          socure: action.payload,
        },
      };
    case ActionType.TRANSUNION:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          transunion: action.payload,
        },
      };
    case ActionType.CACHE_DATE:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          cache_expiry_date: action.payload,
        },
      };
    case ActionType.LOAD_CHAT:
      return {
        ...state,
        openChat: action.payload,
      };
    case ActionType.LOAD_CURRENCY_CONFIGURATIONS:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          currency: {
            ...state.tenant.currency,
            defaultCurrencySymbol: action.payload.symbol,
            defaultCurrencyCode: action.payload.code,
          },
        },
      };
    case ActionType.CHANGE_USER_LOGGED_IN_STATE:
      return {
        ...state,
        isUserLoggedIn: action.payload,
      };
    case ActionType.UPDATE_HEADER_FOOTER: {
      return {
        ...state,
        social_media_icons: action.payload.icons,
        header_links: action.payload.header_links,
        footer_links: action.payload.footer_links,
        dealer_code: action.payload.dealor_id,
        collapse_state: false,
      };
    }
    case ActionType.UPDATE_CUSTOMER: {
      return {
        ...state,
        user: {
          ...state.user,
          customer: action.payload,
        },
      };
    }
    case ActionType.UPDATE_SCORE: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          softPullFetch: action.payload,
        },
      };
    }
    case ActionType.UPDATE_STATES_OPTIONS: {
      return {
        ...state,
        states: collectionIntoSelectOptions(action.payload, "name", "name"),
        statesOriginalRes: action.payload,
      };
    }

    case ActionType.UPDATE_FNI_OPTIONS: {
      if (action.payload.type === "products") {
        return {
          ...state,
          fniOptions: {
            ...state.fniOptions,
            products: action.payload.options,
          },
        };
      } else {
        return {
          ...state,
          fniOptions: {
            ...state.fniOptions,
            bundles: action.payload.options,
          },
        };
      }
    }
    case ActionType.UPDATE_CONTRACT_DOCS: {
      return {
        ...state,
        contractDocs: action.payload,
      };
    }
    case ActionType.PROGRAM_ERROR: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          error: action.payload,
        },
      };
    }
    case ActionType.INFO_ERROR: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          contactError: action.payload,
        },
      };
    }
    case ActionType.CREDIT_FREEZE: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          creditfreez: action.payload,
        },
      };
    }

    case ActionType.UPDATE_COLLAPSE_STATE:
      return {
        ...state,
        collapse_state: !state.ui.collapseState,
      };

    case ActionType.UPDATE_DEALER_PROFILE:
      return {
        ...state,
        dealer: {
          ...state.dealer,
          ...action.payload,
        },
      };

    case ActionType.UPDATE_TENANT_THEME:
      const head = document.head;
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = action?.payload?.stylesheet;
      head.appendChild(link);
      return {
        ...state,
        theme: action.payload,
      };
    case ActionType.DEFAULT_STATE:
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.payload.ui,
        },
        user: {
          ...state.user,
          customer: {
            ...state.user.customer,
            ...action.payload.cusomer,
          },
        },
        tenant: {
          ...state.tenant,
          ...action.payload.tenant,
        },
        lender: {
          ...action.payload.lender,
          ...state.lender,
        },
        order: {
          ...state.order,
          ...action.payload.order,
        },
        dealer: {
          ...state.dealer,
          ...action.payload.dealer,
        },
        configuration: {
          ...state.configuration,
          ...action.payload.configuration,
        },
      };
    case ActionType.LOAD_DEFAULT_LENDER_CONFIGURATIONS:
      return {
        ...state,
        lender: {
          ...state.lender,
          defaultLender: action.payload,
          lenderSubmissions: [action.payload.id],
        },
      };

    case ActionType.ORDER_FRAUD_STATUS:
      return {
        ...state,
        order: {
          ...state.order,
          customer_info: {
            ...state.order.customer_info,
            fraud_status: action.payload,
          },
        },
      };
    case ActionType.ORDER_INSURANCE:
      return {
        ...state,
        order: {
          ...state.order,
          Insurance_Inforation: {
            insurer_name: action.payload.insurer_name,
            identifier: action.payload.identifier,
            agent_phone: action.payload.agent_phone,
            order_reference_id: action.payload.order_reference_id,
            created_by: action.payload.created_by,
            updated_by: action.payload.updated_by,
          },
        },
      };
    case ActionType.UPDATE_LENDERS:
      return {
        ...state,
        lender: {
          ...state.lender,
          lenders: action.payload,
        },
      };
    case ActionType.UPDATE_LENDER_SUBMISSIONS:
      return {
        ...state,
        lender: {
          ...state.lender,
          lenderSubmissions: action.payload,
        },
      };
    case ActionType.UPDATE_INSURANCE:
      return {
        ...state,
        order: {
          ...state.order,
          company_provider: action.payload,
        },
      };
    case ActionType.UPDATE_DISCLAIMERS:
      return {
        ...state,
        dealer: {
          ...state.dealer,
          disclaimer: action.payload,
        },
      };
    case ActionType.LOGO:
      return {
        ...state,
        dealer: {
          ...state.dealer,
          logo: action.payload,
        },
      };
    case ActionType.ORDER_STATUS:
      return {
        ...state,
        order: {
          ...state.order,
          status: action.payload,
        },
      };
    case ActionType.UPDATE_REBATE:
      return {
        ...state,
        dealer: {
          ...state.dealer,
          rebates: action.payload,
        },
      };
    case ActionType.ADDONS:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          addOns: action.payload,
        },
      };
    case ActionType.PREINSTALLCOUNT:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          preInstalled: action.payload,
        },
      };
    case ActionType.TOGGLE_DISCLAIMER:
      return {
        ...state,
        ui: {
          ...state.ui,
          toggles: { ...state.ui.toggles, disclaimer: action.payload },
        },
      };
    case ActionType.TOGGLE_DRAWER_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          toggles: { ...state.ui.toggles, drawer: action.payload },
        },
      };
    case ActionType.TOGGLE_MODAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          toggles: { ...state.ui.toggles, modal: action.payload },
        },
      };
    case ActionType.UPDATE_ORDER_NET_FINANCE_AMOUNT:
      return {
        ...state,
        order: {
          ...state.order,
          net_finance_amount: action.payload,
        },
      };
    default:
      return state;
  }
};
