import { FinanceLeasePricingDrawerContentIntro } from "./fl-dealer-pricing/fl-dealer-pricing-intro/fl-dealer-pricing-intro.component";
import { FinanceLeasePricingDrawerContentIntroError } from "./fl-dealer-pricing/fl-dealer-pricing-intro/fl-dealer-pricing-intro-error.component";
import { FinanceLeasePricingDrawerContentInfo } from "./fl-dealer-pricing/fl-dealer-pricing-info/fl-dealer-pricing-info.component";
import { FinanceLeasePricingDrawerContentInfoError } from "./fl-dealer-pricing/fl-dealer-pricing-info/fl-dealer-pricing-info-error.component";
import { FinanceLeasePricingDrawerContentApr } from "./fl-dealer-pricing/fl-dealer-pricing-apr/fl-dealer-pricing-apr.component";
import { FinanceLeaseAuthenticatedUserInfo } from "./fl-authenticated-user/fl-authenticated-user-info/fl-authenticated-user-info.component";
import { FinanceLeaseAuthenticatedUserApr } from "./fl-authenticated-user/fl-authenticated-user-apr/fl-authenticated-user-apr.component";
import { FinanceLeaseAuthenticatedUserCreditFrozen } from "./fl-authenticated-user/fl-authenticated-user-credit/fl-authenticated-user-credit-frozen.component";
import { FinanceLeaseAuthenticatedUserCreditUnFrozen } from "./fl-authenticated-user/fl-authenticated-user-credit/fl-authenticated-user-credit-unfrozen.component";

import "./fl-pricing-drawer-content.scss";
import { useState } from "react";
import { useAppContext } from "@src/context/app-context";
import { useGeneralContext } from "@src/context/general-context/general-context";

export const FinanceLeasePricingDrawerContent = () => {
  const [isContentInfo, setIsContentInfo] = useState(false);
  const [isContentApr, setIsContentApr] = useState(false);
  const [contantinfoerror, setContantinfoerror] = useState(false);
  const userCredentials = JSON.parse(localStorage.getItem("customer") || "{}");

  const {
    state: {
      configuration: { error, creditfreez, contactError },
      user: {
        customer: { fetchedData, first_name },
      },
    },
  } = useAppContext();

  const toggleContentInfo = () => {
    setIsContentInfo(true);
  };
  const toggleContentApr = () => {
    setIsContentApr(true);
    setIsContentInfo(false);
  };

  return (
    <>
      {userCredentials?.access_token ? (
        <>
          {!isContentApr && !error && !creditfreez && (
            <FinanceLeaseAuthenticatedUserInfo
              toggleContentApr={toggleContentApr}
            />
          )}
          {fetchedData && isContentApr && (
            <>
              <FinanceLeasePricingDrawerContentApr />
              {/* <FinanceLeaseAuthenticatedUserApr /> */}
            </>
          )}
          {isContentInfo && !contantinfoerror && first_name ? (
            <FinanceLeasePricingDrawerContentInfo
              toggleContentApr={toggleContentApr}
            />
          ) : (
            contactError && <FinanceLeasePricingDrawerContentInfoError />
          )}
          {error && <FinanceLeasePricingDrawerContentIntroError />}
          {creditfreez && <FinanceLeaseAuthenticatedUserCreditFrozen />}
        </>
      ) : (
        <>
          {!isContentInfo && !error && (
            <FinanceLeasePricingDrawerContentIntro
              toggleContentInfo={toggleContentInfo}
            />
          )}
        </>
      )}
    </>
  );
};
