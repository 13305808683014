import { useAppContext } from "@src/context/app-context";
import { useCalculateNfa, useCalculateOrderQuotation } from "./program";
import { useEffect, useState } from "react";
import { setOrderInfo, updateDealerProfile } from "@src/context/app-actions";
import { getV2Payload } from "@src/helpers";
import { getNFAPayload } from "@src/helpers/payloadHelper";

export const useComputedQuotation = () => {
  const [orderValues, setOrderValues] = useState<any>(null);

  const { mutate: calculateOrder } = useCalculateOrderQuotation();
  const { mutate: calculateNFA } = useCalculateNfa();
  const {
    state: {
      dealer: { dealer_address, dealer_code },
      lender: {
        defaultLender: { external_code },
      },
      user: {
        customer: {
          customer_addresses: { mailing },
        },
      },
      order,
    },
    dispatch: appDispatch,
  } = useAppContext();

  useEffect(() => {
    if (orderValues) {
      const nfaPayload = getNFAPayload(orderValues, dealer_code);

      calculateNFA(
        {
          ...nfaPayload,
          taxes: [],
        },

        {
          onSuccess: ({ net_financed_amount }) => {
            calculateOrder(
              {
                ...nfaPayload,
                ...getV2Payload(
                  orderValues,
                  dealer_code,
                  mailing,
                  dealer_address,
                  external_code
                ),
                net_financed_amount: net_financed_amount,
              },
              {
                onSuccess: (data) => {
                  appDispatch(updateDealerProfile({ creditfreez: true }));
                  appDispatch(
                    setOrderInfo({
                      ...order,
                      ...orderValues,
                      estimated_monthly_payment: data.monthly_payment,
                      net_finance_amount: net_financed_amount,
                      total_score: order.credit_score,
                    })
                  );
                  return data;
                },
                onError: (error) => {
                  setOrderValues(null);
                  appDispatch(updateDealerProfile({ creditfreez: true }));
                  console.error("Error fetching pricing:", error);
                },
              }
            );
          },
          onError: (err) => {
            console.error("Error fetching net financing amount:", err);
          },
        }
      );
    }
  }, [orderValues]);

  return {
    orderValues,
    setOrderValues,
  };
};
