import { useAppContext } from "@src/context/app-context";
import { CashTabContent } from "./cash-tab-content/cash-tab-content";
import { FinanceTabContent } from "./finance-tab-content/finance-tab-content";
import { LeaseTabContent } from "./lease-tab-content/lease-tab-content";
import "./update-payment-terms-drawer-content.scss";
import { Tab } from "@src/components/atoms";
import { useEffect, useMemo, useState } from "react";
import { toLower, isEmpty } from "lodash";
import { useComputedQuotation } from "@hooks/useCalculateQuatation";
import { getAllProgramPayload } from "@src/helpers";
import { useGetAllPrograms } from "@src/hooks";
import { useGeneralContext } from "@src/context/general-context/general-context";
export const UpdatePaymentTermsDrawerContent = ({
  openSoftpullDrawer,
}: {
  openSoftpullDrawer?: () => void;
}) => {
  const {
    contextState: { financetype },
  } = useGeneralContext();

  const tabComponents: Record<string, JSX.Element> = {
    lease: <LeaseTabContent />,
    finance: <FinanceTabContent />,
    cash: <CashTabContent />,
  };

  const {
    state: {
      order: { finance_type, ...restorder },
      dealer: { available_finance_types, dealer_code, dealer_address },
      lender: { defaultLender },
      tenant: { companyCode },
    },
  } = useAppContext();
  const { setOrderValues } = useComputedQuotation();
  const { mutate: getProgram } = useGetAllPrograms();

  // Generate tab items dynamically from available_finance_types
  const tabItems = useMemo(() => {
    return available_finance_types.map((type, index) => ({
      key: String(index + 1),
      label: type,
      children: tabComponents[toLower(type)] || null, // Fallback to `null` if component is missing
    }));
  }, [available_finance_types]);

  // Determine default active tab
  const activeTabKey = useMemo(() => {
    const foundTab = tabItems.find(
      (tab) => toLower(finance_type || "") === toLower(tab.label)
    );
    return foundTab?.key || tabItems[0]?.key || "1";
  }, [finance_type, tabItems]);

  const [defaultActiveKey, setDefaultActiveKey] = useState(activeTabKey);

  useEffect(() => {
    setDefaultActiveKey(activeTabKey);
  }, [activeTabKey]);

  const handelOnChange = (e) => {
    const selectedTab = tabItems.find(({ key }) => toLower(e) === toLower(key));
    if (selectedTab) {
      fetchProgramData(selectedTab?.label);
    }
  };
  const fetchProgramData = (financeType) => {
    getProgram(
      {
        ...getAllProgramPayload(
          restorder?.order_asset,
          companyCode,
          defaultLender.external_code,
          dealer_code,
          financeType,
          restorder?.contract_term,
          restorder.annual_usage,
          restorder.credit_rating,
          restorder.credit_score
        ),
        tier: "",
      },
      {
        onSuccess: (programRes: any) => {
          if (!isEmpty(programRes.detail) || programRes.length === 0) {
            console.error("ERROR: Sorry No Program Found");
            return;
          } else {
            setOrderValues({
              finance_type: financeType,
              ...restorder,
              customerV2Address: dealer_address,
              apr: programRes.final_customer_rate,
              down_payment:
                programRes.downpayment_chart[0]?.default_down_payment ?? 0,
              rental_mode: programRes?.rental_mode,
              rv_balloon_percentage: programRes?.assetRvAmount,
              downpayment_chart: programRes.downpayment_chart,
            });
          }
        },
        onError: (err) => {
          console.error("err", err);
        },
      }
    );
  };

  return (
    <>
      <div className="update-payment">
        <div className="update-payment__headline">
          <div className="text--subsection-1">Update your payment terms.</div>
        </div>
        <div className="update-payment__inner">
          <div className="update-payment__tabs">
            <Tab
              defaultActiveKey={defaultActiveKey}
              items={tabItems}
              onChange={handelOnChange}
              mode="condensed"
            />
          </div>
        </div>
      </div>
    </>
  );
};
