import { Radio } from "@src/components/atoms/radio/radio.component";
import "./financial-services.scss";
import CardLogo from "../../../shared/assets/img/cardLogo.png";
import { FinancialServiceCardSelect } from "@src/components/atoms";
import { formatCurrency } from "@src/helpers/appHelper";
import { OrderSubmission } from "@src/components/organisms/credit-application/finance-offers/01-select-offer/select-offer/select-offer";

export const FinancialServiceCard = ({
  setValues,
  values,
  orderSubmissions,
}) => {
  return (
    <>
      <div className="offer-group">
        {orderSubmissions.map((submission: OrderSubmission, ind) => (
          <div key={ind} className="offer-container">
            <FinancialServiceCardSelect
              logo={CardLogo}
              title={submission.provider}
              subTitle={
                "Tailored lease and finance options from the people who know BMW best."
              }
              price={formatCurrency(submission.monthly_payment)}
              downCash={formatCurrency(submission.down_payment)}
              apr={`${submission.apr}% APR`}
              term={`${submission.contract_term} month finance term`}
              selected={values.submission.id === submission.id}
              hasCheckbox={
                <Radio
                  label="Select"
                  onChange={(e) =>
                    setValues((prev) => ({
                      ...prev,
                      submission: submission,
                    }))
                  }
                  name="financialserviceRadio"
                  id="financial-service-radio1"
                  htmlFor="financial-service-radio"
                />
              }
            />
          </div>
        ))}
      </div>
    </>
  );
};
