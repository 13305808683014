import "./product-details-drawer-content.scss";
import { Button, Image } from "@src/components/atoms";

import Image1 from "../../../../shared/assets/img/product-details-drawer-img.png";
import { FinancialProduct } from "@src/types";
import { formatCurrency } from "@src/helpers";

export const ProductDetailsDrawerContent = ({
  productDetails,
}: {
  productDetails?: FinancialProduct;
}) => {
  if (!productDetails) return null;

  return (
    <div className="product-details-drawer-content">
      <div className="product-details-drawer">
        <div className="product-details-drawer__left">
          <div className="product-details-drawer__image">
            <Image
              src={
                productDetails.image?.length
                  ? productDetails.image[0].location
                  : ""
              }
              alt="image"
              aspectRatio="1:1"
            />
            <i
              className="bmw-icon bmw-icon--after data-icon"
              data-icon="arrows_maximize"
            ></i>
          </div>
        </div>
        <div className="product-details-drawer__right">
          <div className="product-details-drawer__right--inner">
            <span className="product-details-drawer__subtitle text--disclaimer">
              Provided by {productDetails.provider_name}
            </span>
            <div className="text--subsection-1">
              {productDetails.coverage_name}
            </div>
            <div className="product-details-drawer__right--price-detail">
              <div className="product-details-drawer__right--price-section">
                <span className="card-select-default-checked text--body-2">
                  Monthly cost:
                </span>
                <div className="text--promo-focus-price">
                  {formatCurrency(productDetails.monthly_impact)}
                  <span className="text--h3">/month</span>
                </div>
                <p className="text--label">
                  {" "}
                  {formatCurrency(productDetails.fni_product_amount)} total
                </p>
              </div>
              <p className="text--body-2">{productDetails.description}</p>
            </div>
            <div className="product-details-drawer__price-list">
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  <i
                    className="bmw-icon bmw-icon--after data-icon"
                    data-icon="clock"
                  ></i>
                  Terms
                </span>
                <span className="pricing-details__item__price text--body-2">
                  {productDetails.term_months} Months
                </span>
              </div>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  <i
                    className="bmw-icon bmw-icon--after data-icon"
                    data-icon="street"
                  ></i>
                  Mileage
                </span>
                <span className="pricing-details__item__price text--body-2">
                  {productDetails.term_miles} miles
                </span>
              </div>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  <i
                    className="bmw-icon bmw-icon--after data-icon"
                    data-icon="insurance_calculator"
                  ></i>
                  Deductible
                  <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $XXX
                </span>
              </div>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  <i
                    className="bmw-icon bmw-icon--after data-icon"
                    data-icon="license_plate"
                  ></i>
                  Surcharges
                  <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $XXX
                </span>
              </div>
            </div>
            <p className="text--disclaimer text-muted">
              For coverage adjustments or more questions, please
              <a
                href="/contact-dealer"
                className="text-dark text--disclaimer-link text-underline fw-normal"
              >
                contact dealer
              </a>
              .
            </p>
          </div>

          <div className="product-details-drawer__right--links-section">
            {productDetails.video_url && (
              <Button
                htmlType="button"
                text="Play video"
                linkButton
                withIcon
                onClick={() => window.open(productDetails.video_url, "_blank")}
                dataIcon="play"
              />
            )}

            <Button
              htmlType={"button"}
              text="Download brochure"
              linkButton
              withIcon
              dataIcon="download"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
