import {
  LegalDisclaimer,
  OneTrustlDisclaimer,
} from "@src/components/molecules";
import "./disclaimer-notification.scss";
import { useEffect, useState } from "react";
import { useAppContext } from "@src/context/app-context";
import { useAddCustomerConsent } from "@src/hooks";
import { isEmpty } from "lodash";

export const DisclaimerNotification = () => {
  const { mutate: addConsent } = useAddCustomerConsent();

  const {
    state: {
      ui: {
        toggles: {
          disclaimer: { isOpen },
        },
        isMobileView,
      },
      dealer: {
        disclaimer: {
          incentivesDiscountsDisclaimer: {
            changesDisclaimer,
            disclaimer_name,
            disclaimer_version,
          },
        },
      },
      user: {
        customer: { reference_id, disclaimer_info },
      },
    },
  } = useAppContext();

  const [disclaimerState, setDisclaimerState] = useState({
    showDisclaimer: !isMobileView,
    showSection: true,
    showLegalDisclaimer: true,
  });

  const isDisclaimerAgreed = JSON.parse(
    localStorage.getItem("incentives-discounts-disclaimer") || "false"
  );
  const setIncentiveDisclaimerAccepted = () => {
    localStorage.setItem(
      "incentives-discounts-disclaimer",
      JSON.stringify(true)
    );
  };

  useEffect(() => {
    if (!!changesDisclaimer) {
      const result = Array.isArray(disclaimer_info)
        ? disclaimer_info.filter(
            (disclaimer_need) =>
              disclaimer_need?.disclaimer_name ===
              "incentives-discounts-disclaimer"
          )
        : [];

      if (!isEmpty(result)) {
        setIncentiveDisclaimerAccepted();
      }
      if (isEmpty(result) && reference_id !== "") {
        addLegalConsent();
      }
    }
  }, [changesDisclaimer, disclaimer_info, reference_id]);

  useEffect(() => {
    if (!isOpen) {
      setDisclaimerState({
        ...disclaimerState,
        showLegalDisclaimer: false,
        showDisclaimer: false,
      });
    } else if (isOpen && !isDisclaimerAgreed) {
      setDisclaimerState({
        ...disclaimerState,
        showLegalDisclaimer: true,
        showDisclaimer: true,
      });
    }
  }, [isOpen, isDisclaimerAgreed]);

  const addLegalConsent = async () => {
    if (changesDisclaimer !== "") {
      addConsent(
        {
          customer_reference_id: reference_id,
          disclaimer_name: disclaimer_name || "",
          disclaimer_version: disclaimer_version || "",
          order_reference_id: "",
        },
        {
          onSuccess: () => {
            setIncentiveDisclaimerAccepted();
          },
        }
      );
    }
  };

  const onClickButton = () => {
    setIncentiveDisclaimerAccepted();
    if (isMobileView) {
      setDisclaimerState({
        ...disclaimerState,
        showLegalDisclaimer: false,
        showDisclaimer: true,
      });
    } else {
      setDisclaimerState({
        ...disclaimerState,
        showSection: false,
      });
    }
  };

  if (!disclaimerState.showSection || isDisclaimerAgreed) return null;

  return (
    <>
      <div className="disclaimer-notification-wrap">
        {disclaimerState.showLegalDisclaimer && (
          <LegalDisclaimer onClickButton={onClickButton} />
        )}
        {disclaimerState.showDisclaimer && <OneTrustlDisclaimer />}
      </div>
    </>
  );
};
