import { Button, Image } from "@src/components/atoms";
import "./vehicle-card.style.scss";
import Image_URL from "../../../shared/assets/img/vehicle-large.png";
import FinanceImage from "../../../shared/assets/img/finance.png";
import { VehicleCardProps } from "./vehicle-card.props";
import { useAppContext } from "@src/context/app-context";
import { formatCurrency } from "@src/helpers/appHelper";

export const VehicleCard = ({
  hasHeader,
  hasFooter,
  vehicleInfo = true,
  vehicleImg = true,
  vehicleFinanceImg,
  hasVehicleFinancing,
  cardPaymentInfo,
  cashDownInfo,
  cashOfferInfo,
  onAprClick,
  onUpdate,
  onDetails,
}: VehicleCardProps) => {
  const {
    state: {
      order: {
        estimated_monthly_payment,
        down_payment_value,
        contract_term,
        order_asset,
      },
    },
  } = useAppContext();
  return (
    <div className="vehicle-card">
      {hasHeader && (
        <div className="vehicle-card-header text--body-2">
          <span className="vehicle-card-reservation-title">
            Vehicle reserved for
          </span>
          <span className="vehicle-card-reservation-time">00:00:00</span>
        </div>
      )}
      <div className="vehicle-card-inner-container">
        {vehicleInfo && (
          <>
            <h4 className="vehicle-card-title text--subsection-1">
              {order_asset.year} {order_asset.model}
            </h4>
            <div className="vehicle-subtitle-container">
              <div className="vehicle-card-state-container">
                <span className="vehicle-card-state text--label">
                  At Dealership
                </span>
              </div>
              <span className="vehicle-card-vin text--disclaimer">
                VIN#: {order_asset.vin}
              </span>
            </div>
          </>
        )}
        {vehicleImg && (
          <div className="vehicle-img">
            <Image
              src={order_asset?.photo_urls[0]?.location}
              alt="Vehicle"
              aspectRatio="16:9"
              containedImage
            />
          </div>
        )}
        {vehicleFinanceImg && (
          <img
            className="vehicle-card-loyalty-image"
            src={FinanceImage}
            alt="Loyalty Card"
          />
        )}
        <div className="vehicle-card-payment-info-row">
          <span className="vehicle-card-financing-title text--body-1 vehicle-card-info vehicle-card-info-icon bmw-icon bmw-icon--after">
            Est. Financing
          </span>
          <span className="vehicle-card-financing-value text--body-focus-price-1">
            {formatCurrency(estimated_monthly_payment)}/month
          </span>
        </div>
        {hasVehicleFinancing && (
          <div className="vehicle-card-payment-info-row">
            <span className="vehicle-card-financing-title" />
            <div className="vehicle-card-financing-container">
              <span className="vehicle-card-payment-value text--body-focus-price vehicle-card-up-arrow bmw-icon bmw-icon--before">
                $10
              </span>
              <span className="vehicle-card-payment-value vehicle-card-offer-text text--disclaimer">
                /month
              </span>
            </div>
          </div>
        )}
        {cardPaymentInfo && (
          <>
            <div className="vehicle-card-payment-info-row">
              <span className="vehicle-card-payment-title text--body-2 vehicle-card-info vehicle-card-info-icon bmw-icon bmw-icon--after">
                MSRP
              </span>
              <span className="text--body-focus-price">
                {formatCurrency(order_asset.msrp)}
              </span>
            </div>
            <hr className="vehicle-card-separator" />
            <div className="vehicle-card-payment-info-row">
              <span className="vehicle-card-payment-title text--body-2">
                Cash Down
              </span>
              <span className="text--body-focus-price">
                {formatCurrency(down_payment_value)}
              </span>
            </div>
            <div className="vehicle-card-payment-info-row">
              <span className="vehicle-card-payment-title text--body-2">
                Term
              </span>
              <span className="text--body-focus-price">
                {contract_term} months
              </span>
            </div>
            <div className="vehicle-card-cta">
              <Button
                htmlType={"button"}
                text="Details"
                size="small"
                className="vehicle-card-cta"
                onClick={onDetails}
              />
            </div>
          </>
        )}
        {/* Financing detail */}
        {cashDownInfo && (
          <div className="financing-cash-info">
            <ul>
              <li>MSRP $ 48,670</li>
              <li>60 month term</li>
              <li>$15,000 cash down</li>
            </ul>
            <Button htmlType={"button"} text="Update" onClick={onUpdate} />
          </div>
        )}
        {/* Cash Offer */}
        {cashOfferInfo && (
          <div className="cash-offer">
            <div className="vehicle-card-payment-info-row">
              <span className="vehicle-card-financing-title text--body-1 vehicle-card-info vehicle-card-info-icon bmw-icon bmw-icon--after">
                Cash offer
              </span>
              <span className="vehicle-card-financing-value text--body-focus-price-1">
                $XX,XXX
              </span>
            </div>
            <div>
              <Button
                htmlType={"button"}
                text="Update"
                size="small"
                className="vehicle-card-cta cash-offer__btn"
                onClick={onUpdate}
              />
            </div>
          </div>
        )}
      </div>

      {/* Footer */}
      {hasFooter && (
        <div className="vehicle-card-footer">
          <p className="vehicle-card__value text-white text--body-2">
            <span
              className="bmw-icon bmw-icon--before data-icon"
              data-icon="search_offer"
            ></span>{" "}
            APR X.XX%
          </p>
          <Button
            onDark
            linkButton
            text="Get accurate APR %"
            onClick={onAprClick}
            withIcon
            isIconEnd
            dataIcon="arrow_chevron_right"
            htmlType="button"
          />
        </div>
      )}
    </div>
  );
};
