import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";

import AuthChecker from "@components/atoms/password-checker/password-checker.coponent";
import CheckoutPage from "@pages/checkout-page/checkout-page";
import FinanceOfferPage from "@pages/finance-offer-page/finance-offer-page";
import MitekImageCapture from "@pages/poc/mitek-page";

// extra page for test components
import { PreviewComponent } from "@src/components/components-preview/component-preview";
import DealStatePage from "@src/pages/deal-state/deal-state-page";
import ReturningToDr from "@src/pages/returning-to-dr/returning-to-dr";
import {
  ContractPreviewPage,
  EsignContractsPage,
  IncentivesAndDiscountsPage,
  PreCheckoutPage,
  ProtectionAndWarrantyPage,
  StartVerificationPage,
  TradeInPage,
} from "@src/pages";
import { AccessoriesPage } from "@src/pages/build-your-deal/accessories-page/accessories-page";
import { ReviewPage } from "@src/pages/build-your-deal/review-page/review-page";
import { DrStartPage } from "@src/pages/dr-start/dr-start-page";
import { WaitingHubPage } from "@src/pages/waiting-hub/waiting-hub-page";
import LoginAuthChecker from "./login-auth-checker";
import { GeneralContextProvider } from "@context/general-context/general-context";
import { AuthContextProvider } from "./auth-context";
import { extractVehicleOrderDetailsFromURL } from "@src/helpers/appHelper";
import AdobeLaunchPage from "@src/pages/poc/adobe-launch";

const urlParams = extractVehicleOrderDetailsFromURL(window.location.pathname);
const dealRefPath = urlParams.dealRefNumber ? "/deal/:dealRefNumber" : "";

export const ROUTING_PATH = {
  CHECKOUT: "/checkout",
  DEAL_ID_PAGE: `/checkout${dealRefPath}`,
  FINANCE_OFFER: "/finance-offer",
  FINANCE_OFFER_WITH_DEAL: `/finance-offer${dealRefPath}`,
  RETURNING_TO_DR: "/returning-dr",
  DEAL_STATE_PAGE: `/deal-state${dealRefPath}/:state`,
  PREVIEW_COMPONENT: "/component-preview",
  MISNAP_MITEK_POC: "/misnap-mobile",
  INCENTIVES_AND_DISCOUNTS_PAGE: `/incentives-and-discounts${dealRefPath}`,
  PROTECTION_AND_WARRANTY_PAGE: "/protection-and-warranty",
  FRAUD_CHECK: `/dealer-contact${dealRefPath}`,
  ACCESSORIES_PAGE: `/accessories${dealRefPath}`,
  REVIEW_PAGE: "/review",
  TRADE_IN_PAGE: `/trade-in${dealRefPath}`,
  DR_START_PAGE: "/dr-start",
  WAITING_HUB_PAGE: "/waiting-hub",
  PRE_CHECKOUT_PAGE: "/pre-checkout",
  SSO: "/sso",
  START_VERIFICATION_PAGE: "/start-verification",
  CONTRACT_PREVIEW_PAGE: `/contract-preview${dealRefPath}`,
  ESIGN_CONTRACTS_PAGE: "/esign-contracts",
  ADOBE_LAUNCH_PAGE_FOR_CM: "/adobe-launch",
};

const ErrorPage = () => {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
      <a href={ROUTING_PATH.CHECKOUT}>Go to Checkout</a>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={ROUTING_PATH.CHECKOUT} replace />,
  },
  {
    path: ROUTING_PATH.DEAL_ID_PAGE,
    element: (
      <LoginAuthChecker>
        <CheckoutPage />
      </LoginAuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.FINANCE_OFFER_WITH_DEAL,
    element: (
      <LoginAuthChecker>
        <FinanceOfferPage />
      </LoginAuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.DEAL_STATE_PAGE,
    element: (
      <LoginAuthChecker>
        <DealStatePage />
      </LoginAuthChecker>
    ),
  },

  {
    path: ROUTING_PATH.RETURNING_TO_DR,
    element: (
      <AuthChecker>
        <ReturningToDr />
      </AuthChecker>
    ),
  },

  {
    path: ROUTING_PATH.FRAUD_CHECK,
    element: (
      <LoginAuthChecker>
        <DealStatePage error />
      </LoginAuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.INCENTIVES_AND_DISCOUNTS_PAGE,
    element: (
      <GeneralContextProvider>
        <AuthContextProvider>
          <LoginAuthChecker>
            <IncentivesAndDiscountsPage />
          </LoginAuthChecker>
        </AuthContextProvider>
      </GeneralContextProvider>
    ),
  },

  {
    path: ROUTING_PATH.SSO,
    element: <LoginAuthChecker />,
  },
  {
    path: ROUTING_PATH.PROTECTION_AND_WARRANTY_PAGE,
    element: (
      <GeneralContextProvider>
        <AuthContextProvider>
          <LoginAuthChecker>
            <ProtectionAndWarrantyPage />
          </LoginAuthChecker>
        </AuthContextProvider>
      </GeneralContextProvider>
    ),
  },

  {
    path: ROUTING_PATH.ACCESSORIES_PAGE,
    element: (
      <AuthContextProvider>
        <GeneralContextProvider>
          <LoginAuthChecker>
            <AccessoriesPage />
          </LoginAuthChecker>
        </GeneralContextProvider>
      </AuthContextProvider>
    ),
  },
  {
    path: ROUTING_PATH.DR_START_PAGE,
    element: (
      <GeneralContextProvider>
        <AuthContextProvider>
          <DrStartPage />
        </AuthContextProvider>
      </GeneralContextProvider>
    ),
  },
  {
    path: ROUTING_PATH.WAITING_HUB_PAGE,
    element: (
      <GeneralContextProvider>
        <AuthChecker>
          <WaitingHubPage />
        </AuthChecker>
      </GeneralContextProvider>
    ),
  },

  {
    path: ROUTING_PATH.REVIEW_PAGE,
    element: (
      <GeneralContextProvider>
        <AuthChecker>
          <ReviewPage />
        </AuthChecker>
      </GeneralContextProvider>
    ),
  },
  {
    path: ROUTING_PATH.PREVIEW_COMPONENT,
    element: (
      <GeneralContextProvider>
        <AuthChecker>
          <PreviewComponent />
        </AuthChecker>
      </GeneralContextProvider>
    ),
  },
  {
    path: ROUTING_PATH.TRADE_IN_PAGE,
    element: (
      <GeneralContextProvider>
        <AuthChecker>
          <LoginAuthChecker>
            <TradeInPage />
          </LoginAuthChecker>
        </AuthChecker>
      </GeneralContextProvider>
    ),
  },
  {
    path: ROUTING_PATH.PRE_CHECKOUT_PAGE,
    element: (
      <GeneralContextProvider>
        <AuthChecker>
          <PreCheckoutPage />
        </AuthChecker>
      </GeneralContextProvider>
    ),
  },
  {
    path: ROUTING_PATH.MISNAP_MITEK_POC,
    element: (
      <GeneralContextProvider>
        <AuthChecker>
          <MitekImageCapture />
        </AuthChecker>
      </GeneralContextProvider>
    ),
  },
  {
    path: ROUTING_PATH.START_VERIFICATION_PAGE,
    element: (
      <GeneralContextProvider>
        <AuthChecker>
          <StartVerificationPage />
        </AuthChecker>
      </GeneralContextProvider>
    ),
  },
  {
    path: ROUTING_PATH.CONTRACT_PREVIEW_PAGE,
    element: (
      <LoginAuthChecker>
        <ContractPreviewPage />
      </LoginAuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.ESIGN_CONTRACTS_PAGE,
    element: (
      <GeneralContextProvider>
        <AuthChecker>
          <EsignContractsPage />
        </AuthChecker>
      </GeneralContextProvider>
    ),
  },
  {
    path: ROUTING_PATH.ADOBE_LAUNCH_PAGE_FOR_CM,
    element: (
      <AuthChecker>
        <AdobeLaunchPage />
      </AuthChecker>
    ),
  },
  {
    path: "*", // Matches all undefined routes
    element: <ErrorPage />,
  },
]);

export const RouterProviderWrapper = () => {
  return <RouterProvider router={router} />;
};
