import { Button } from "@src/components/atoms";
import { useState } from "react";
import clsx from "clsx";
import { ChatWindow } from "./chat-window/chat-window.component";
import "./chat.scss";

interface ChatProps {
  status?: "online" | "offline" | "agentJoins";
  messageCount?: string;
}

export const Chat: React.FC<ChatProps> = ({
  status = "offline",
  messageCount = 1,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={clsx("chat-wrap", {
        "chat-online-wrap": status == "online",
        "chat-agent-joins-wrap": status == "agentJoins",
      })}
    >
      <div className="chat-btn-wrap">
        <Button
          htmlType="button"
          type="default"
          onlyIcon
          dataIcon="speech_bubble"
          className="chat-btn"
          onClick={() => setOpen(!open)}
        />
        {status == "agentJoins" && (
          <div className="chat-count">{messageCount}</div>
        )}
      </div>
      <ChatWindow open={open} setOpen={setOpen} status={status} />
    </div>
  );
};
