import { useAppContext } from "@src/context/app-context";
import { useCallback, useEffect } from "react";
import {
  getPenRatesPayload,
  getV2Payload,
  IntegrationProvider,
  LenderIntegrationType,
} from "@src/helpers";
import {
  useGetFNIBundles,
  useGetFNIProducts,
  useGetMonthlyImpactOnFNIProducts,
  useGetRates,
} from "./fni-products";
import { useIntegrationProvider } from "./integration";
import { updateFniOptions } from "@src/context/app-actions";
import { processRates } from "@src/helpers/fniProductsHelper";
import { useComputedQuotation } from "./useCalculateQuatation";
import { getNFAPayload } from "@src/helpers/payloadHelper";

export const useMappedFNIProducts = () => {
  //apiHooks
  const { mutate: getFNIBundles } = useGetFNIBundles();
  const { mutate: getFNIProducts } = useGetFNIProducts();
  const { mutate: getRates } = useGetRates();
  const { mutate: getMonthlyImpactFNIProducts } =
    useGetMonthlyImpactOnFNIProducts();
  const { mutate: getActivePenIntegration } = useIntegrationProvider();
  //customHooks
  const { setOrderValues } = useComputedQuotation();

  const {
    state: {
      tenant: { company_id },
      fniOptions: { bundles, products },
      order,
      dealer: { dealer_code, pen_dealer_id, dealer_address },
      lender: {
        defaultLender: { external_code },
      },
      user: {
        customer: {
          customer_addresses: { mailing },
        },
      },
    },
    dispatch: appDispatch,
  } = useAppContext();

  const { order_asset, finance_type, apr, contract_term } = order;

  useEffect(() => {
    getActivePenIntegration(
      {
        companyId: company_id.toString(),
        dealerCode: dealer_code,
        integrationType: IntegrationProvider.PEN,
        providerName: LenderIntegrationType.FNI_PRODUCTS,
      },
      {
        onSuccess: (integration: any) => {
          if (integration.is_active) {
            getFNIProducts(
              { dealerCode: dealer_code, companyId: company_id.toString() },
              {
                onSuccess: ({ result }) => {
                  const ratesPayload = {
                    ...getPenRatesPayload(
                      order_asset,
                      pen_dealer_id,
                      finance_type,
                      apr,
                      contract_term
                    ),
                    products_info: result.map((fnIProduct: any) => ({
                      product_id: fnIProduct.product_id,
                      starting_mileage: 0,
                      ending_mileage: 10000,
                      filters: [
                        {
                          term_months: contract_term,
                        },
                      ],
                    })),
                  };

                  getRates(ratesPayload, {
                    onSuccess: ({ rates: { rate } }) => {
                      const coverages = processRates(rate, result);

                      const coveragePayload = {
                        ...{
                          ...getNFAPayload(order, dealer_code),
                          ...getV2Payload(
                            order,
                            dealer_code,
                            mailing,
                            dealer_address,
                            external_code
                          ),
                        },
                        fni_products: coverages,
                      };

                      getMonthlyImpactFNIProducts(coveragePayload as any, {
                        onSuccess: ({ products }) => {
                          let individualProdCoverages: any = [];
                          if (products) {
                            individualProdCoverages = coverages.map(
                              (coverage) => {
                                const product = products.find(
                                  (p) => p.name === coverage.product_name
                                );
                                if (product) {
                                  return {
                                    ...coverage,
                                    monthly_impact: parseFloat(
                                      product.monthly_impact.toFixed(2)
                                    ),
                                  };
                                }
                                return coverage;
                              }
                            );
                          }
                          appDispatch(
                            updateFniOptions({
                              options: individualProdCoverages,
                              type: "products",
                            })
                          );

                          ///Bundles
                          getFNIBundles(
                            {
                              dealerCode: dealer_code,
                              companyId: company_id.toString(),
                            },
                            {
                              onSuccess: ({ results }) => {
                                let bundles: any = [];
                                results.forEach((bundle) => {
                                  const ratesPayload = {
                                    ...getPenRatesPayload(
                                      order_asset,
                                      pen_dealer_id,
                                      finance_type,
                                      apr,
                                      contract_term
                                    ),
                                    products_info:
                                      bundle.financial_products.map(
                                        (fnIProduct: any) => ({
                                          product_id: fnIProduct.product_id,
                                          starting_mileage: 0,
                                          ending_mileage: 10000,
                                          filters: [
                                            {
                                              term_months: contract_term,
                                            },
                                          ],
                                        })
                                      ),
                                  };

                                  getRates(ratesPayload, {
                                    onSuccess: ({ rates: { rate } }) => {
                                      const updatedBundleMarkup =
                                        bundle.financial_products.map(
                                          (product) => ({
                                            ...product,
                                            markup: bundle.markup,
                                          })
                                        );

                                      const bundleFniCoverages = processRates(
                                        rate,
                                        updatedBundleMarkup
                                      );

                                      const coveragePayload = {
                                        ...{
                                          ...getNFAPayload(order, dealer_code),
                                          ...getV2Payload(
                                            order,
                                            dealer_code,
                                            mailing,
                                            dealer_address,
                                            external_code
                                          ),
                                        },
                                        fni_products: bundleFniCoverages,
                                      };
                                      getMonthlyImpactFNIProducts(
                                        coveragePayload as any,
                                        {
                                          onSuccess: ({ products }) => {
                                            let updatedCoverages: any = [];
                                            if (products) {
                                              let totalMonthly = 0;
                                              let totalCost = 0;
                                              let individualProductCost = 0;
                                              let individualCostSaving = 0;
                                              updatedCoverages =
                                                bundleFniCoverages.map(
                                                  (coverage) => {
                                                    // Find the matching product
                                                    const product =
                                                      products.find(
                                                        (p) =>
                                                          p.name ===
                                                          coverage.product_name
                                                      );
                                                    const individualProd =
                                                      individualProdCoverages.find(
                                                        (p) =>
                                                          p.product_name ===
                                                          coverage.product_name
                                                      );
                                                    if (individualProd) {
                                                      individualCostSaving =
                                                        individualProd.fni_product_amount -
                                                        coverage.fni_product_amount;
                                                      individualProductCost +=
                                                        individualProd.fni_product_amount;
                                                    }

                                                    totalCost +=
                                                      coverage.fni_product_amount;
                                                    // If the product is found, add the monthly_impact to the coverage
                                                    if (product) {
                                                      let individualMonthlySaving = 0;
                                                      if (individualProd) {
                                                        individualMonthlySaving =
                                                          individualProd.monthly_impact -
                                                          parseFloat(
                                                            product.monthly_impact.toFixed(
                                                              2
                                                            )
                                                          );
                                                      }

                                                      totalMonthly +=
                                                        parseFloat(
                                                          product.monthly_impact.toFixed(
                                                            2
                                                          )
                                                        );

                                                      return {
                                                        ...coverage,
                                                        monthly_impact:
                                                          parseFloat(
                                                            product.monthly_impact.toFixed(
                                                              2
                                                            )
                                                          ),
                                                        individualCostSaving:
                                                          individualCostSaving,
                                                        individualMonthlySaving:
                                                          individualMonthlySaving,
                                                      };
                                                    }
                                                    return coverage;
                                                  }
                                                );

                                              bundles.push({
                                                ...bundle,
                                                financial_products:
                                                  updatedCoverages,
                                                monthlySaving: parseFloat(
                                                  totalMonthly.toFixed(2)
                                                ),
                                                costSaving: totalCost,
                                                percentSaving: parseFloat(
                                                  (
                                                    100 -
                                                    (totalCost /
                                                      individualProductCost) *
                                                      100
                                                  ).toFixed(2)
                                                ),
                                              });
                                            }

                                            appDispatch(
                                              updateFniOptions({
                                                options: bundles,
                                                type: "bundles",
                                              })
                                            );
                                          },
                                          onError: (error) => {
                                            console.error("Error: ", error);
                                          },
                                        }
                                      );
                                    },
                                    onError: (error) => {
                                      console.error("Error: ", error);
                                    },
                                  });
                                });
                              },
                              onError: (error) => {
                                console.error("Error: ", error);
                              },
                            }
                          );
                        },
                        onError: (error) => {
                          console.error("Error: ", error);
                        },
                      });
                    },
                    onError: (error) => {
                      console.error("Error: ", error);
                    },
                  });
                },
                onError: (error) => {
                  console.error("Error: ", error);
                },
              }
            );
          }
        },
      }
    );
  }, []);

  const handleBundleUpsert = (bundleDetails, selected?: boolean) => {
    const updatedFniProductsWithBundleId = bundleDetails.financial_products.map(
      (product) => ({
        ...product,
        bundle_id: bundleDetails.id,
        applied_price: product.fni_product_amount,
        taxable: product.is_taxable,
        term: product.term_months,
      })
    );

    if (selected) {
      const updatedOrderFnI = order.order_fnI.filter((fni) => {
        return !bundleDetails.financial_products.some(
          (bundleProduct) =>
            fni.product_name === bundleProduct.product_name &&
            fni.term === bundleProduct.term_months &&
            !fni.bundle_id
        );
      });
      setOrderValues({
        ...order,
        order_fnI: Array.isArray(order.order_fnI)
          ? [...updatedOrderFnI, ...updatedFniProductsWithBundleId]
          : [...updatedFniProductsWithBundleId],
      });
    } else {
      setOrderValues({
        ...order,
        order_fnI: order.order_fnI.filter(
          (fni) => fni.bundle_id !== bundleDetails.id
        ),
      });
    }
  };

  const handleProductUpsert = (productDetails, selected?: boolean) => {
    const updatedFniProduct = {
      ...productDetails,
      applied_price: productDetails.fni_product_amount,
      taxable: productDetails.is_taxable,
      term: productDetails.term_months,
      mileage: productDetails.term_miles,
    };

    if (selected) {
      setOrderValues({
        ...order,
        order_fnI: Array.isArray(order.order_fnI)
          ? [...order.order_fnI, updatedFniProduct]
          : [...updatedFniProduct],
      });
    } else {
      setOrderValues({
        ...order,
        order_fnI: order.order_fnI.filter(
          (fni) =>
            fni.product_name !== updatedFniProduct.product_name &&
            fni.term === updatedFniProduct.term_months &&
            fni.mileage === updatedFniProduct.term_miles &&
            !fni.bundle_id
        ),
      });
    }
  };

  return {
    handleBundleUpsert,
    handleProductUpsert,
  };
};
