import { validatePhoneNumberLength } from "@src/helpers/appHelper";
import * as yup from "yup";

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const basicValidator = yup
  .object({
    first_name: yup
      .string()
      .required("First Name is required")
      .max(50, "First Name must be at most 50 characters"),
    last_name: yup
      .string()
      .required("Last Name is required")
      .max(50, "Last Name must be at most 50 characters"),
    middle_name: yup
      .string()
      .nullable()
      .max(50, "Middle Name must be at most 50 characters"),
    date_of_birth: yup.date().required("Date of birth is required"),
    ssn: yup
      .string()
      .required("Social Security Number is required")
      .matches(/^\d{9}$/, "SSN must be exactly 9 digits"),
  })
  .required();

export const jointCustomerValidator = yup.object({
  first_name: yup
    .string()
    .required("Full Name is required")
    .max(150, "Full Name must be at most 150 characters"),
  updated_by: yup
    .string()
    .matches(EMAIL_REGEX, "Invalid email address")
    .max(255, "Email address is too long")
    .required("Email is required"),
  co_applicant_check: yup
    .boolean()
    .oneOf([true], "Co-applicant must be selected"),
});

export const basicValidatorSoftPull = yup
  .object({
    first_name: yup
      .string()
      .required("First Name is required")
      .max(50, "First Name must be at most 50 characters"),
    last_name: yup
      .string()
      .required("Last Name is required")
      .max(50, "Last Name must be at most 50 characters"),
    address_line_1: yup.string().required("Address 1 is required"),
    address_line_2: yup.string().nullable(),
    state_name: yup.string().required("State is required"),
    zip_code: yup
      .string()
      .matches(/^[0-9]{5}$/, "Invalid ZIP code")
      .required("ZIP code is required"),
    city: yup.string().required("City is required"),
  })
  .required();
