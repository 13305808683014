import { Button } from "@src/components/atoms";
import { BasicCustomerInfo } from "@src/types/customer";
import "./vehicle-ownership-completed.scss";
import { useReducer } from "react";
import { formatUSPhoneNumberWithCountryCode } from "@src/helpers/appHelper";
import {
  initialState,
  reducer,
} from "@pages/checkout-page/checkout-page.state";
import dayjs from "dayjs";

export const VehicleOwnershipCompleted = ({
  changeStateOfEditModeHandler,
  customerInfo,
}: {
  customerInfo: Omit<BasicCustomerInfo, "email"> & { updated_by: string };
  changeStateOfEditModeHandler: (step: string) => void;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <div className="completed-form-container">
      <div className="completed-form">
        <div className="completed-form header-wrapper">
          <h2 className="section-header upper-label">
            <span className="section-header text--subsection-2 text-secondary">
              01
            </span>
            <span className="section-header text--subsection-2">
              Vehicle ownership.
            </span>
          </h2>

          <Button
            htmlType={"button"}
            text="Edit"
            onClick={() => changeStateOfEditModeHandler("step-one")}
            size="small"
          />
        </div>
        <div className="completed-form content-container">
          <div className="completed-form content-container__header-wrapper">
            <div className="completed-form content-container__header-wrapper__header text--body-2">
              This vehicle is for you.
            </div>
            <div className="completed-form content-container__header-wrapper__sub-header text--body-2">
              You are the primary driver. Other drivers may use the vehicle
              occasionally.
            </div>
          </div>
          <div className="completed-form content-container__divider" />
          <div className="completed-form content-container__personal-details">
            <div className="completed-form content-container__personal-details__detail-row">
              <div className="column">
                <span className="column detail-label text--label">
                  First Name
                </span>
                <span className="column detail-value text--body-2">
                  {customerInfo.first_name}
                </span>
              </div>
              <div className="column">
                <span className="column detail-label text--label">
                  {" "}
                  Middle Name
                </span>
                <span className="column detail-value text--body-2">
                  {customerInfo.middle_name}
                </span>
              </div>
              <div className="column">
                <span className="column detail-label text--label">
                  Last Name
                </span>
                <span className="column detail-value text--body-2">
                  {customerInfo.last_name}
                </span>
              </div>
            </div>
            <div className="completed-form content-container__personal-details__detail-row">
              <div className="column">
                <span className="column detail-label text--disclaimer">
                  Date of birth
                </span>
                <span className="column detail-value text--body-2">
                  {dayjs(customerInfo.date_of_birth).format("MM.DD.YYYY")}
                </span>
              </div>
              <div className="column">
                <span className="column detail-label text--label">
                  Social Security Number
                </span>
                <span className="column detail-value text--body-2">
                  *** ** ****
                </span>
              </div>
            </div>
            <div className="completed-form content-container__personal-details__detail-row">
              <div className="column">
                <span className="column detail-label text--label">
                  Phone Number
                </span>
                <span className="column detail-value text--body-2">
                  {formatUSPhoneNumberWithCountryCode(customerInfo.home_number)}
                </span>
              </div>
              <div className="column">
                <span className="column detail-label text--label">
                  Mobile number
                </span>
                <span className="column detail-value text--body-2">
                  {formatUSPhoneNumberWithCountryCode(
                    customerInfo.mobile_number
                  )}
                </span>
              </div>
            </div>
            <div className="completed-form content-container__personal-details__detail-row">
              <div className="column">
                <span className="column detail-label text--label">
                  Email address
                </span>
                <span className="column detail-value text--body-2">
                  {customerInfo.updated_by}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
